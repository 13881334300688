import axiosInstance from "../../../Utils/axios";

export const getLeads = (postsPerPage, status, loanType, startDate, endDate, facilityType) =>
  axiosInstance.get(
    `api/v1/lead-app/loan-lead?offset=0&limit=${postsPerPage}&ordering=-id&status=${
      status?.id ? status?.id : 1
    }&start_date=${startDate ? startDate.toISOString().slice(0, 10) : ""}&end_date=${
      endDate ? endDate.toISOString().slice(0, 10) : ""
    }&loanType=${loanType ? (loanType !== null ? loanType?.id : "") : ""}&facility_Type=${
      facilityType ? facilityType?.id : ""
    }`
  );

export const getAllLeads = (postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/loan-lead?offset=0&limit=${postsPerPage}&ordering=-id&`);

export const createLeads = (body) => {
  return axiosInstance.post(`api/v1/lead-app/loan-lead`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getSpecificLeads = (id) => axiosInstance.get(`api/v1/lead-app/loan-lead/${id}/summary`);

export const updateLeads = (id, body) =>
  axiosInstance.patch(`api/v1/lead-app/loan-lead/${id}`, body, {
    "Content-Type": "multipart/form-data",
  });

export const convertLeadProgress = (id) => axiosInstance.patch(`api/v1/lead-app/progress-loan-lead/${id}`);

export const convertLoanLead = (id) => axiosInstance.patch(`api/v1/lead-app/convert-loan-lead/${id}`);

export const cancelLoanLead = (body) => axiosInstance.post(`api/v1/lead-app/cancel-loan-lead/`, body);

export const handleSearch = (search, postsPerPage, status, loanType) =>
  axiosInstance.get(
    `api/v1/lead-app/loan-lead?offset=0&limit=${postsPerPage}&search=${search}&status=${
      status !== null ? status?.id : ""
    }&loanType=${loanType !== null ? loanType?.id : ""}`
  );

export const deletePhoto = (id, body) => axiosInstance.patch(`api/v1/lead-app/loan-lead/${id}`, body);

export const createReason = (body) => {
  return axiosInstance.post(`api/v1/lead-app/reason/`, body);
};
