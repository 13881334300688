import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLeads = createAsyncThunk("leads/getLeads", async (value, { rejectWithValue }) => {
  const { postsPerPage, status, loanType, startDate, endDate, facilityType } = value;
  try {
    const { data } = await API.getLeads(postsPerPage, status, loanType, startDate, endDate, facilityType);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getSpecificLeads = createAsyncThunk("leads/getSpecificLeads", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificLeads(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getAllLeads = createAsyncThunk("leads/getAllLeads", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllLeads(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLeads = createAsyncThunk("leads/createLeads", async (values, { rejectWithValue, dispatch }) => {
  const {
    customerAddress,
    customerName,
    customerEmail,
    primaryContactNumber,
    branchVisitDate,
    secondaryContactNumber,
    loanAmount,
    tenureDurationInMonths,
    loanType,
    facilityType,
    applicantId,
    businessRegistrationNo,
    companyId,
    accountNo,
    dateOfBirth,
  } = values;

  try {
    const body = new FormData();

    body.append("customerAddress", customerAddress);
    body.append("accountNo", accountNo);
    body.append("primaryContactNumber", primaryContactNumber);
    body.append("customerName", customerName);
    body.append("customerEmail", customerEmail);
    body.append("branchVisitDate", branchVisitDate);

    body.append("loanAmount", loanAmount);
    body.append("tenureDurationInMonths", tenureDurationInMonths);
    body.append("loanType", loanType);

    body.append("facilityType", facilityType);

    body.append("secondaryContactNumber", secondaryContactNumber);
    // body.append("customerGroup", customerGroup);
    if (applicantId) {
      body.append("applicantId", applicantId);
    }
    if (businessRegistrationNo) {
      body.append("businessRegistrationNo", businessRegistrationNo);
    }
    if (companyId) {
      body.append("companyId", companyId);
    }
    if (dateOfBirth) {
      body.append("dateOfBirth", dateOfBirth);
    }

    const { data } = await API.createLeads(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});
export const updateLeads = createAsyncThunk("leads/updateLeads", async (updateData, { rejectWithValue, dispatch }) => {
  const { id, values } = updateData;
  const {
    accountNo,
    customerAddress,
    customerName,
    customerEmail,
    branchVisitDate,
    loanAmount,
    tenureDurationInMonths,
    loanType,
    facilityType,
    applicantId,
    businessRegistrationNo,
    secondaryContactNumber,
    companyId,
    dateOfBirth,
  } = values;

  try {
    const body = new FormData();

    body.append("customerAddress", customerAddress);
    body.append("customerName", customerName);
    body.append("customerEmail", customerEmail);

    if (secondaryContactNumber) {
      body.append("secondaryContactNumber", secondaryContactNumber);
    }
    if (accountNo) {
      body.append("accountNo", accountNo);
    }
    if (branchVisitDate) {
      body.append("branchVisitDate", branchVisitDate);
    }
    if (dateOfBirth) {
      body.append("dateOfBirth", dateOfBirth);
    }

    if (tenureDurationInMonths) {
      body.append("tenureDurationInMonths", tenureDurationInMonths);
    }
    if (loanAmount) {
      body.append("loanAmount", loanAmount);
    }
    if (loanType) {
      body.append("loanType", loanType);
    }

    // facility type is array of keys

    if (facilityType) {
      facilityType.forEach((item) => {
        body.append("facilityType", item);
      });
    }

    if (applicantId) {
      body.append("applicantId", applicantId);
    }
    if (businessRegistrationNo) {
      body.append("businessRegistrationNo", businessRegistrationNo);
    }
    if (companyId) {
      body.append("companyId", companyId);
    }

    const { data } = await API.updateLeads(id, body);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const convertLeadProgress = createAsyncThunk(
  "lead/convertLeadProgress",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const id = data;
    try {
      const { data } = await API.convertLeadProgress(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to convert.");
    }
  }
);
export const convertLoanLead = createAsyncThunk(
  "lead/convertLoanLead",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const { data } = await API.convertLoanLead(id); // Ensure 'id' is defined
      return data;
    } catch (error) {
      return rejectWithValue("Failed to convert to convert loan.");
    }
  }
);
export const cancelLoanLead = createAsyncThunk(
  "leads/cancelLoanLead",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.cancelLoanLead(JSON.stringify(values));
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.msg[0]);
    }
  }
);

export const handleSearch = createAsyncThunk("lead/handleSearch", async (value, { rejectWithValue }) => {
  const { search, postsPerPage, status, loanType } = value;
  try {
    const { data } = await API.handleSearch(search, postsPerPage, status, loanType);
    return data;
  } catch (error) {
    rejectWithValue("Could not find data");
  }
});

// delete photo
export const deletePhoto = createAsyncThunk("country/deletePhoto", async (id, { rejectWithValue }) => {
  try {
    const body = JSON.stringify({ applicantId: "" });
    const { data } = await API.deletePhoto(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to delete photo.");
  }
});
export const deleteCompanyPhoto = createAsyncThunk("country/deleteCompanyId", async (id, { rejectWithValue }) => {
  try {
    const body = JSON.stringify({ companyId: "" });
    const { data } = await API.deletePhoto(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to delete photo.");
  }
});

export const createReason = createAsyncThunk("leads/createLeads", async (values, { rejectWithValue, dispatch }) => {
  const { reason } = values;

  try {
    const body = new FormData();
    body.append("reason", reason);
    const { data } = await API.createReason(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});
