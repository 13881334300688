import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

export const getFacility = createAsyncThunk(
  "facility/getFacility",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getFacility(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get facilitys.");
    }
  }
);

export const getAllFacility = createAsyncThunk(
  "facility/getAllFacility",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllFacility();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get facilitys.");
    }
  }
);
export const getSpecificFacility = createAsyncThunk(
  "facility/getSpecificFacility",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificFacility(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Facility type");
    }
  }
);

export const getPrevious = createAsyncThunk(
  "facility/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get facilitys.");
    }
  }
);

export const getNext = createAsyncThunk("facility/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue("Failed to get facilitys.");
  }
});

export const createFacility = createAsyncThunk(
  "facility/createFacility",
  async (data, { rejectWithValue, dispatch }) => {
    const { name } = data;
    try {
      const body = JSON.stringify({ name });
      const { data } = await API.createFacility(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create facility.");
    }
  }
);
export const updateFacility = createAsyncThunk(
  "facility/updateFacility",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, name } = data;

    try {
      const body = JSON.stringify({ name });
      const { data } = await API.updateFacility(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create facility.");
    }
  }
);
export const deleteFacility = createAsyncThunk(
  "facility/deleteFacility",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.deleteFacility(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to Delete facility.");
    }
  }
);

export const handleSearch = createAsyncThunk(
  "facility/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get facility");
    }
  }
);
