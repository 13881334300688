import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { successFunction, errorFunction } from "../../../Components/Alert/Alert";
import axiosInstance from "../../../Utils/axios";
import {
  createOrganization,
  updateOrganization,
  getOrganization,
  deletePhoto,
} from "../Redux/thunk";
import { fiscalSessionTypes } from "../../../Utils/constants";
import { FILE_SIZE, SUPPORTED_FORMATS, LOGO_SIZE, FAVICON_SIZE } from "../../../Utils/image";
import Thumb from "../../../Components/Thumb";
import Loader from "../../../Components/Loader";
import Dropzone from "../../../Components/CommonDropzone/Dropzone";
import AsyncSelect from "../../../Components/CommonAsyncSelectField/AsyncSelect";
import Select from "../../../Components/CommonSelectField/Select";
import TextField from "../../../Components/TextField/TextField";
import Button from "../../../Components/Buttons/Button";
import Checkbox from "../../../Components/CommonCheckbox/Checkbox";
import { closeModal } from "../../../Redux/Layout/layoutSlice";

const CreateOrganization = ({ dispatch, postsPerPage, setShowUserSetupModal }) => {
  const organization = useSelector((state) => state.organization.organization);
  const loading = useSelector((state) => state.organization.loading);
  const loadingUpdated = useSelector((state) => state.organization.loadingUpdated);
  const edit = useSelector((state) => state.organization.edit);
  const [lock, setLock] = useState(false);
  const [logo, setLogo] = useState(null);
  const [signature, setSignature] = useState(null);
  const [stamp, setStamp] = useState(null);

  const initialState = {
    name: edit ? organization?.name : "",
    address: edit ? organization?.address : "",
    phoneNo: edit ? organization?.phoneNo : "",
    email: edit ? organization?.email : "",
    country: edit ? organization?.country : null,
    currency: edit ? organization?.currency : null,
    fiscalSessionType: edit
      ? fiscalSessionTypes?.find((type) => type.id === organization.fiscalSessionType)
      : null,
    logo: "",
    signature: "",
    stamp: "",
    active: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required.")
      .min(1, "Organization Name should be at least 1 character")
      .max(100, "Organization Name should be 100 characters"),
    address: Yup.string().max(100, "Address should be of 100 characters"),
    phoneNo: Yup.string().max(15, "Phone number should not greater than 15 digits."),
    email: Yup.string()
      .email("Please enter valid email address.")
      .max(70, "Email should be of 70 characters"),
    fiscalSessionType: Yup.object().required("Required!"),
    country: Yup.object().required("Required!").typeError("Required!"),
    currency: Yup.object().required("Required!").typeError("Required!"),
    logo: Yup.mixed()
      .test(
        "fileSize",
        "File must be less than 20kb",
        (file) => !file || (file && file.size <= 20 * 1024)
      )
      .test(
        "fileType",
        "Unsupported File Format.",
        (file) => !file || (file && SUPPORTED_FORMATS.includes(file.type))
      ),
    signature: Yup.mixed()
      .test(
        "fileSize",
        "File must be less than 20kb",
        (file) => !file || (file && file.size <= 20 * 1024)
      )
      .test(
        "fileType",
        "Unsupported File Format.",
        (file) => !file || (file && SUPPORTED_FORMATS.includes(file.type))
      ),
    stamp: Yup.mixed()
      .test(
        "fileSize",
        "File must be less than 20kb.",
        (file) => !file || (file && file.size <= 20 * 1024)
      )
      .test(
        "fileType",
        "Unsupported File Format.",
        (file) => !file || (file && SUPPORTED_FORMATS.includes(file.type))
      ),
    active: Yup.bool(),
  });

  const onSubmit = (values) => {
    const { country, currency, fiscalSessionType, logo, stamp, signature } = values;
    if (!edit) {
      dispatch(
        createOrganization({
          ...values,
          country: country?.id,
          currency: currency?.id,
          fiscalSessionType: fiscalSessionType?.id,
        })
      )
        .unwrap()
        .then(() => {
          successFunction("Organization created successfully.");
          dispatch(getOrganization(postsPerPage));
          setShowUserSetupModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    } else {
      let updateData = {
        id: organization?.id,
        values: {
          ...values,
          country: country?.id,
          currency: currency?.id,
          fiscalSessionType: fiscalSessionType?.id,
          logo: typeof logo === "object" ? logo : undefined,
          stamp: typeof stamp === "object" ? stamp : undefined,
          signature: typeof signature === "object" ? signature : undefined,
        },
      };
      dispatch(updateOrganization(updateData))
        .unwrap()
        .then(() => {
          successFunction("Organization updated successfully.");
          dispatch(getOrganization(postsPerPage));
          setShowUserSetupModal(false);
        })
        .catch((error) => errorFunction(error));
    }
  };

  const loadOptionsCountry = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(
      `/api/v1/core-app/country-list?search=${search}&offset=${offset}&limit=${limit}`
    );
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.next ? offset + 10 : offset,
        limit: 10,
      },
    };
  };

  const loadOptionsCurrency = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(
      `/api/v1/core-app/currency?search=${search}&offset=${offset}&limit=${limit}`
    );
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.next ? offset + 10 : offset,
        limit: 10,
      },
    };
  };

  return (
    <>
      {(loading || loadingUpdated) && <Loader />}

      <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="create-organization-wrapper">
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-6">
                        <div className="my-2">
                          <TextField
                            type="text"
                            name="name"
                            label="Organization Name"
                            required
                            formikRequired={formik?.errors?.name && formik?.touched?.name}
                            placeholder="Name"
                            onChange={(e) => {
                              formik.setFieldValue("name", e.target.value);
                            }}
                            autoFocus={true}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <TextField
                            type="text"
                            name="address"
                            label="Address"
                            placeholder="Address"
                            required={true}
                            formikRequired={formik?.errors?.address && formik?.touched?.address}
                            onChange={(e) => {
                              formik.setFieldValue("address", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <TextField
                            type="text"
                            name="phoneNo"
                            label="Phone Number"
                            required={true}
                            formikRequired={formik?.errors?.phoneNo && formik?.touched?.phoneNo}
                            placeholder="98*********"
                            onChange={(e) => {
                              formik.setFieldValue("phoneNo", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="my-2">
                          <TextField
                            type="text"
                            name="email"
                            label="Email"
                            required={true}
                            formikRequired={formik?.errors?.email && formik?.touched?.email}
                            placeholder="Enter Email"
                            onChange={(e) => {
                              formik.setFieldValue("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <AsyncSelect
                            value={formik.values.country}
                            name="country"
                            label="Country"
                            required={true}
                            formikRequired={formik?.errors?.country && formik?.touched?.country}
                            getOptionLabel={(option) => `${option?.name} `}
                            getOptionValue={(option) => `${option?.id}`}
                            onChange={(selected) => {
                              formik.setFieldValue("country", selected);
                            }}
                            loadOptions={loadOptionsCountry}
                            additional={{
                              offset: 0,
                              limit: 10,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <AsyncSelect
                            value={formik.values.currency}
                            name="currency"
                            label="Currency"
                            required={true}
                            formikRequired={formik?.errors?.currency && formik?.touched?.currency}
                            getOptionLabel={(option) => `${option?.name} `}
                            getOptionValue={(option) => `${option?.id}`}
                            onChange={(selected) => {
                              formik.setFieldValue("currency", selected);
                            }}
                            loadOptions={loadOptionsCurrency}
                            additional={{
                              offset: 0,
                              limit: 10,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="my-2">
                          <Select
                            value={formik?.values?.fiscalSessionType}
                            name="fiscalSessionType"
                            label="Fiscal Session Type"
                            required={true}
                            formikRequired={
                              formik?.errors?.fiscalSessionType &&
                              formik?.touched?.fiscalSessionType
                            }
                            options={fiscalSessionTypes}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            onChange={(selected) => {
                              formik.setFieldValue("fiscalSessionType", selected);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col-6">
                        <div className="my-2">
                          <Dropzone
                            name="logo"
                            label="Logo"
                            removePhoto={() => {
                              formik.setFieldValue("logo", "");
                              setLogo(null);
                            }}
                            onChange={(event) => {
                              formik.setFieldValue("logo", event.target.files[0]);
                              let reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = () => setLogo([reader.result]);
                            }}
                            displayImage={
                              logo ? (
                                <Thumb thumb={logo} />
                              ) : organization && organization.logo && !logo ? (
                                <Thumb thumb={organization.logo} />
                              ) : (
                                ""
                              )
                            }
                            error={formik.errors.logo}
                            text={"File must be less than 20kb"}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <Dropzone
                            name="stamp"
                            label="Organization Stamp"
                            removePhoto={() => {
                              formik.setFieldValue("stamp", "");
                              setStamp(null);
                            }}
                            onChange={(event) => {
                              formik.setFieldValue("stamp", event.target.files[0]);
                              let reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = () => setStamp([reader.result]);
                            }}
                            displayImage={
                              stamp ? (
                                <Thumb thumb={stamp} />
                              ) : organization && organization.stamp && !stamp ? (
                                <Thumb thumb={organization.stamp} />
                              ) : (
                                ""
                              )
                            }
                            error={formik.errors.stamp}
                            text={"File must be less than 20kb"}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="my-2">
                          <Dropzone
                            name="signature"
                            label="Owner Signature"
                            removePhoto={() => {
                              formik.setFieldValue("signature", "");
                              setSignature(null);
                            }}
                            onChange={(event) => {
                              formik.setFieldValue("signature", event.target.files[0]);
                              let reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = () => setSignature([reader.result]);
                            }}
                            displayImage={
                              signature ? (
                                <Thumb thumb={signature} />
                              ) : organization && organization.signature && !signature ? (
                                <Thumb thumb={organization.signature} />
                              ) : (
                                ""
                              )
                            }
                            error={formik.errors.signature}
                            text={"File must be less than 20kb"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-2 d-flex justify-content-center align-items-center">
                  <Checkbox name="active" label="Active" />
                </div>
              </div>
              <div className="col-12 p-0 text-right">
                <div className="mt-3  d-flex justify-content-end align-items-center">
                  <Button
                    btnType="submit"
                    className="btn create-button"
                    createButton={true}
                    disabled={edit ? lock || loadingUpdated : loading || lock}
                    title={edit ? "Update" : "Save"}
                    content={edit ? "Update" : "Save"}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateOrganization;
