import { createSlice } from "@reduxjs/toolkit";
import { getInspectionDuration } from "./thunk";

const initialState = {
  inspectionDuration: null,
  edit: false,
  loading: false,
  previous: null,
  next: null,
};

export const inspectionDurationSlice = createSlice({
  name: "inspectionDuration",
  initialState,
  reducers: {
    clearEditInspectionDuration: (state, action) => {
      state.edit = false;
      state.loading = false;
    },
    editInspectionDuration: (state) => {
      state.edit = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInspectionDuration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInspectionDuration.fulfilled, (state, action) => {
      state.loading = false;
      state.inspectionDuration = action.payload.results[0];
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });
    builder.addCase(getInspectionDuration.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { editInspectionDuration, clearEditInspectionDuration } = inspectionDurationSlice.actions;
export default inspectionDurationSlice.reducer;
