import { AiOutlineHolder } from "react-icons/ai";
import ListingSkeleton from "../../../../Components/Skeleton/ListingSkeleton";
import React, { useRef, useState } from "react";
import DetailActionButton from "../../../../Components/Icons/DetailButtonIcon";
import ColumnResize from "react-table-column-resizer";
import { useDispatch, useSelector } from "react-redux";
import { getLoanInfo } from "../../../Loan/Pages/ViewDetails/Redux/thunk";

const LoanTab = ({ loanType, setLoanType, accountType, newJson, loans, postsPerPage, setPostsPerPage }) => {
  const { loadingLoans } = useSelector((state) => state.loan);
  const { count, allLoanInfo, loading, next } = useSelector((state) => state.leadDetail);
  const listInnerRef = useRef();
  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const viewPermission = permissions.includes("view_loan_info") || isSuperuser;
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState(false);
  const [masterData, setMasterData] = useState({});

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };
  const getAllLoans = (json) => {
    let loans = [];
    json?.accounts?.forEach((account) => {
      account?.loans?.forEach((loan) => {
        loans.push(loan);
      });
    });
    return loans;
  };

  const getLoansByAccountName = (accountName) => {
    let specificLoans = [];
    const account = newJson?.accounts?.find((account) => account?.name === accountName);
    if (account && account.loans) {
      account.loans.forEach((loan) => {
        specificLoans.push(loan);
      });
    }

    return specificLoans;
  };
  const allLoans = getAllLoans(newJson);
  const specificLoans = getLoansByAccountName(accountType);
  return (
    <>
      {postsPerPage === 20 && loadingLoans ? (
        <ListingSkeleton />
      ) : (
        <div className="  p-0 m-0 " style={{ overflow: "auto" }}>
          <div className=" table-scrollable " onScroll={handleScroll} ref={listInnerRef}>
            <p className="m-0 mb-0" style={{ color: "#008eb0", fontSize: "12px", fontWeight: "500" }}>
              Loans
            </p>
            <table style={{ borderSpacing: "0px 5px" }} className="">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Loan Type</th>
                  <th>Loan Amount</th>
                  <th>Due Amount</th>
                  <th>Interest</th>
                  <th>Collateral</th>
                </tr>
              </thead>
              <tbody>
                {accountType === "All"
                  ? allLoans?.map((loan, index) => {
                      const { id, loanType, loanAmount, dueAmount, interest } = loan;
                      return (
                        <React.Fragment key={index + 1}>
                          <tr>
                            <td>{id}</td>
                            <td>{loanType}</td>
                            <td>{loanAmount || "N/A"}</td>
                            <td>{dueAmount || "N/A"}</td>
                            <td>{interest || "N/A"}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setLoanType(id);
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                  : specificLoans?.map((loan, index) => {
                      const { id, loanType, loanAmount, dueAmount, interest } = loan;
                      return (
                        <React.Fragment key={index + 1}>
                          <tr>
                            <td>{id}</td>
                            <td>{loanType}</td>
                            <td>{loanAmount || "N/A"}</td>
                            <td>{dueAmount || "N/A"}</td>
                            <td>{interest || "N/A"}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setLoanType(id);
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
export default LoanTab;
