import axiosInstance from "../../../../../../Utils/axios";

export const getAllBusinessParagraphQuestion = (postsPerPage) =>
  axiosInstance.get(`api/v1/survey/question/?annex_AnnexType=business&limit=20&offset=0&answerFormat=paragraph`);
// create loan api: /api/v1/lead-app/loan-lead/

export const getBusinessQuestion = (id) => axiosInstance.get(`api/v1/loan-inspection-app/business-question?loan=${id}`);

export const createBusinessQuestion = (data) =>
  axiosInstance.post(`api/v1/loan-inspection-app/business-question`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const updateBusinessQuestion = (id, data) =>
  axiosInstance.patch(`api/v1/loan-inspection-app/business-question/${id}`, data);

export const getBooleanQuestionnaire = () =>
  axiosInstance.get(`api/v1/survey/question/?annex_AnnexType=business&limit=20&offset=0&answerFormat=boolean`);

export const getTableQuestionnaire = () =>
  axiosInstance.get(`api/v1/survey/question/?annex_AnnexType=business&limit=20&offset=0&answerFormat=table`);

export const createBusinessQuestionAnswer = (data) => axiosInstance.post(`api/v1/survey/business-answer/`, data);

export const getParagraphBusinessQuestionAnswer = (inspectionId) =>
  axiosInstance.get(`api/v1/survey/business-answer/?answerFormat=paragraph&inspection=${inspectionId}`);

export const getBooleanBusinessQuestionAnswer = (inspectionId) =>
  axiosInstance.get(`api/v1/survey/business-answer/?answerFormat=boolean&inspection=${inspectionId}&limit=20`);

export const getTableBusinessQuestionAnswer = (inspectionId) =>
  axiosInstance.get(`api/v1/survey/business-answer/?answerFormat=table&inspection=${inspectionId}&limit=20`);

export const getInspectionPdfReoprt = (inspectionId) =>
  axiosInstance.get(`api/v1/loan-inspection-app/businees-inspection-pdf/${inspectionId}`);

export const updateBusinessTableQuestionAnswer = (id, data) =>
  axiosInstance.patch(`api/v1/survey/business-answer/${id}/`, data);
