import axiosInstance from "../../../Utils/axios";

// obtaining the paginated data
export const getBusinessNature = (postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/business-nature?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all data
export const getAllBusinessNature = () =>
  axiosInstance.get(`api/v1/lead-app/business-nature?ordering=-id`);

export const getSpecificBusinessNature = (id) =>
  axiosInstance.get(`api/v1/lead-app/business-nature/${id}`);
//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//creating function

export const createBusinessNature = (body) =>
  axiosInstance.post(`api/v1/lead-app/business-nature`, body);
export const updateBusinessNature = (id, body) =>
  axiosInstance.patch(`api/v1/lead-app/business-nature/${id}`, body);
export const deleteBusinessNature = (id) =>
  axiosInstance.delete(`api/v1/lead-app/business-nature/${id}`, id);
//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/lead-app/business-nature?offset=0&limit=${postsPerPage}&search=${search}`
  );
