import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getInspectionDuration = createAsyncThunk("inspectionDuration/getInspectionDuration", async (value, { rejectWithValue }) => {
  try {
    const { data } = await API.getInspectionDuration();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createInspectionDuration = createAsyncThunk("inspectionDuration/createInspectionDuration", async (values, { rejectWithValue }) => {
  try {
    const { data } = await API.createInspectionDuration(values);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInspectionDuration = createAsyncThunk("inspectionDuration/updateInspectionDuration", async (values, { rejectWithValue }) => {
  const { id, durationInDays } = values;

  try {
    const { data } = await API.updateInspectionDuration(id, durationInDays);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
