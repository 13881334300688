import { createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import * as API from "./api";

// get user
export const getUser = createAsyncThunk(
  "user/getUser",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getUser(postsPerPage);
      const filteredData = data.results.filter(
        (user) => user.isSuperuser === false
      );
      return { ...data, results: filteredData, count: filteredData?.length };
    } catch (error) {
      return rejectWithValue("Failed to get users.");
    }
  }
);

// get all users
export const getAllUser = createAsyncThunk(
  "user/getAllUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllUser();
      const filteredData = data.results.filter(
        (user) => user.isSuperuser === false
      );
      return { ...data, results: filteredData };
    } catch (error) {
      return rejectWithValue("Failed to get users.");
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "user/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      const filteredData = data.results.filter(
        (user) => user.isSuperuser === false
      );
      return { ...data, results: filteredData, count: filteredData?.length };
    } catch (error) {
      return rejectWithValue("Failed to get users.");
    }
  }
);
// get next
export const getNext = createAsyncThunk(
  "user/getNext",
  async (next, { rejectWithValue }) => {
    try {
      const { data } = await API.getNext(next);
      const filteredData = data.results.filter(
        (user) => user.isSuperuser === false
      );
      return { ...data, results: filteredData, count: filteredData?.length };
    } catch (error) {
      return rejectWithValue("Failed to get users.");
    }
  }
);

// get particular page
export const getPageUser = createAsyncThunk(
  "user/getPageUser",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageUser(number, postsPerPage);
      const filteredData = data.results.filter(
        (user) => user.isSuperuser === false
      );
      return { ...data, results: filteredData, count: filteredData?.length };
    } catch (error) {
      rejectWithValue("Failed to get users.");
    }
  }
);

// get current user
export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (token, { rejectWithValue }) => {
    try {
      const decoded = token && jwt_decode(token);
      const { data } = await API.getCurrentUser(decoded);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get user.");
    }
  }
);

// create user
export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue, dispatch }) => {
    const {
      fullName,
      email,
      userName,
      address,
      birthDate,
      mobileNo,
      isActive,
      photo,
      groups,
      departments,
      hierarchy,
      gender,
      password,
      confirmPassword,
    } = data;
    try {
      const body = new FormData();

      body.append("fullName", fullName);
      body.append("email", email);
      body.append("userName", userName);
      body.append("address", address);
      if (birthDate !== "") {
        body.append("birthDate", birthDate);
      }
      body.append("mobileNo", mobileNo);
      body.append("isActive", isActive);
      body.append("gender", gender);
      body.append("password", password);
      body.append("confirmPassword", confirmPassword);
      body.append("hierarchy", hierarchy);
      await groups.forEach((group, i) => {
        body.append(`groups[${i}]`, group);
      });
      await departments.forEach((department, i) => {
        body.append(`departments[${i}]`, department);
      });
      if (photo) {
        body.append("photo", photo);
      }
      const { data } = await API.createUser(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create user.");
    }
  }
);
// update user
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, values } = data;
    const {
      fullName,
      email,
      userName,
      address,
      birthDate,
      mobileNo,
      isActive,
      photo,
      groups,
      departments,
      hierarchy,
      gender,
      remarks,
    } = values;
    try {
      const body = new FormData();
      body.append("fullName", fullName);
      body.append("email", email);
      body.append("userName", userName);
      body.append("address", address);
      body.append("birthDate", birthDate);
      body.append("mobileNo", mobileNo);
      body.append("isActive", isActive);
      body.append("hierarchy", hierarchy);
      body.append("gender", gender);
      if (remarks) {
        body.append("remarks", remarks);
      }
      if (photo) {
        body.append("photo", photo);
      }
      await groups.forEach((group, i) => {
        body.append(`groups[${i}]`, group);
      });
      await departments.forEach((department, i) => {
        body.append(`departments[${i}]`, department);
      });

      const { data } = await API.updateUser(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update user.");
    }
  }
);

// get specific user
export const getSpecificUser = createAsyncThunk(
  "user/getSpecificUser",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.getSpecificUser(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get user.");
    }
  }
);

// delete user
export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.deleteUser(id);
      dispatch(getUser(10));
      return data;
    } catch (error) {
      return rejectWithValue("Failed to delete user.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "user/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get user.");
    }
  }
);

// delete photo
export const deletePhoto = createAsyncThunk(
  "user/deletePhoto",
  async (id, { rejectWithValue }) => {
    try {
      const body = JSON.stringify({ photo: "" });
      const { data } = await API.deletePhoto(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to delete photo.");
    }
  }
);
