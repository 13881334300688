import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Redux/Auth/authSlice";
import alertReducer from "../Redux/Alert/alertSlice";
import layoutReducer from "../Redux/Layout/layoutSlice";
import tabsValueReducer from "../Redux/TabsValue/tabsValueSlice";
import leadReducer from "../Pages/Leads/Redux/leadsSlice";
import loanReducer from "../Pages/Loan/Redux/loanSlice";
import departmentReducer from "../Pages/Department/Redux/departmentSlice";
import organizationReducer from "../Pages/Organization/Redux/organizationSlice";
import hierarchyReducer from "../Pages/OrganizationHierarchy/Redux/organizationHierarchySlice";
import countryReducer from "../Pages/Country/Redux/countrySlice";
import currencyReducer from "../Pages/Currency/Redux/currencySlice";
import fiscalSessionADReducer from "../Pages/FiscalSessionAD/Redux/fiscalSessionADSlice";
import fiscalSessionBSReducer from "../Pages/FiscalSessionBS/Redux/fiscalSessionBSSlice";
import userGroupReducer from "../Pages/UserGroup/Redux/userGroupSlice";
import userReducer from "../Pages/User/Redux/userSlice";
import leadDetailReducer from "../Pages/Loan/Pages/ViewDetails/Redux/viewDetailsLeadsSlice";
import systemSelectionReducer from "../Redux/SystemSelection/systemSelectionSlice";
import inspectionReducer from "../Pages/Inspection/Redux/inspectionSlice";
import annexReducer from "../Pages/Annexure/Redux/annexSlice.js";
import notificationReducer from "../Components/CommonPageHeader/redux/notificationSlice.js";
import reportReducer from "../Pages/CustomerDetails/Redux/ReportSlice.js";
import facilityReducer from "../Pages/Facilities/Redux/facilitiesSlice.js";
import businessNatureReducer from "../Pages/BusinessNature/Redux/businessNatureSlice";
import collateralTypeReducer from "../Pages/CollateralType/Redux/collateralTypeSlice";
import collectionAndRecoveryReducer from "../Pages/CollectionAndRecovery/Redux/collectionAndRecoverySlice.js";
import inspectionDurationReducer from "../Pages/InspectionDuration/Redux/inspectionDurationSlice.js";
import businessInspectionReducer from "../Pages/Loan/Pages/ViewDetails/BusinessInspection/Redux/BusinessInspectionSlice.js";
import businessQuestionReducer from "../Pages/Loan/Pages/ViewDetails/BusinessInspection/BusinessQuestionRedux/BusinessQuestionSlice.js";
import dashboardReducer from "../Pages/Dashboard/Redux/dashboardSlice.js";
const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  layout: layoutReducer,
  tabsValue: tabsValueReducer,
  systemSelection: systemSelectionReducer,
  lead: leadReducer,
  loan: loanReducer,
  department: departmentReducer,
  organization: organizationReducer,
  hierarchy: hierarchyReducer,
  country: countryReducer,
  currency: currencyReducer,
  ad: fiscalSessionADReducer,
  bs: fiscalSessionBSReducer,
  user: userReducer,
  userGroup: userGroupReducer,
  leadDetail: leadDetailReducer,
  inspection: inspectionReducer,
  annex: annexReducer,
  notification: notificationReducer,
  report: reportReducer,
  facility: facilityReducer,
  businessNature: businessNatureReducer,
  collateralType: collateralTypeReducer,
  collectionAndRecovery: collectionAndRecoveryReducer,
  inspectionDuration: inspectionDurationReducer,
  businessInspection: businessInspectionReducer,
  businessQuestion: businessQuestionReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
