import { getCustomers, getReport, handleSearch } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  customers: [],
  loading: false,
  error: null,
  next: null,
  count: 0,
};
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearReport: (state) => {
      state.report = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      state.report = action?.payload;
      state.count = action?.payload?.count;
      state.next = action?.payload?.next;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.loading = false;
      state.customers = action?.payload;
      state.count = action?.payload?.count;
      state.next = action?.payload?.next;
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.customers = action?.payload;
      state.count = action?.payload?.count;
      state.next = action?.payload?.next;
    });
    builder.addCase(handleSearch.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { clearReport } = reportSlice.actions;
export default reportSlice.reducer;
