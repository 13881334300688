import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getOrganizationHierarchy = () =>
  axiosInstance.get(
    `api/v1/hierarchy-app/organization-hierarchy?offset=0&limit=0&ordering=-id`
  );

//obtaining all fiscal sessions
export const getAllOrganizationHierarchy = () =>
  axiosInstance.get(`api/v1/hierarchy-app/organization-hierarchy?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageOrganizationHierarchy = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/hierarchy-app/organization-hierarchy?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createOrganizationHierarchy = (body) =>
  axiosInstance.post(`api/v1/hierarchy-app/organization-hierarchy`, body, {
    "Content-Type": "multipart/form-data",
  });
//updating function
export const updateOrganizationHierarchy = (id, body) =>
  axiosInstance.patch(
    `api/v1/hierarchy-app/organization-hierarchy/${id}`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/hierarchy-app/organization-hierarchy?offset=0&limit=${postsPerPage}&search=${search}`
  );

export const checkRedundantData = (name, cancelToken, parent) =>
  axiosInstance.get(
    `api/v1/hierarchy-app/organization-hierarchy?name=${name.trim()}&parent=${parent}`,
    {
      cancelToken: cancelToken.token,
    }
  );
