import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get currency
export const getCurrency = createAsyncThunk(
  "currency/getCurrency",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getCurrency(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get currency.");
    }
  }
);

// get all currencies
export const getAllCurrency = createAsyncThunk(
  "currency/getAllCurrency",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllCurrency();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get currency.");
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "currency/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get currency.");
    }
  }
);
// get next
export const getNext = createAsyncThunk(
  "currency/getNext",
  async (next, { rejectWithValue }) => {
    try {
      const { data } = await API.getNext(next);

      return data;
    } catch (error) {
      return rejectWithValue("Failed to get currency.");
    }
  }
);

// get particular page
export const getPageCurrency = createAsyncThunk(
  "currency/getPageCurrency",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageCurrency(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get currency.");
    }
  }
);

// create currency
export const createCurrency = createAsyncThunk(
  "currency/createCurrency",
  async (data, { rejectWithValue }) => {
    const { name, symbol, code, active } = data;
    try {
      const body = JSON.stringify({ name, symbol, code, active });
      const { data } = await API.createCurrency(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create currency.");
    }
  }
);
export const updateCurrency = createAsyncThunk(
  "currency/updateCurrency",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, symbol, code, active } = values;
    try {
      const body = JSON.stringify({ name, symbol, code, active });
      const { data } = await API.updateCurrency(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update currency.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "currency/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get currency");
    }
  }
);
