import axiosInstance from "../../../Utils/axios";

export const getLoans = (postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/loan-lead?offset=0&limit=${postsPerPage}&status=&ordering=-id`);
// create loan api: /api/v1/lead-app/loan-lead/

export const getAllLoan = (postsPerPage, status, loanType) =>
  axiosInstance.get(`api/v1/lead-app/loan-lead?status=${status}&offset=0&limit=${postsPerPage}&loanType=${loanType}`);

export const getLoan = (id) => axiosInstance.get(`api/v1/lead-app/loan_create/${id}/`);

export const createLoan = (data) =>
  axiosInstance.post(`api/v1/lead-app/loan_create/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const updateLoan = (id, data) => axiosInstance.patch(`api/v1/lead-app/update-loan/${id}`, data);

export const handleSearch = (search, postsPerPage, loanType, status) =>
  axiosInstance.get(
    `api/v1/lead-app/lead-app?status=2&offset=0&limit=${postsPerPage}&search=${search}&status=${
      status !== null ? status?.id : ""
    }&loanType=${loanType !== null ? loanType?.id : ""}`
  );

//api for history
export const getCollateralHistory = (collateralId) =>
  axiosInstance.get(`api/v1/lead-app/collateral/history/${collateralId}/`);

export const getInspectionHistory = (inspectionId) =>
  axiosInstance.get(`api/v1/loan-inspection-app/new-inspection/history/${inspectionId}`);

export const getAllCollateralType = (postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/collateral_type?limit=${postsPerPage}&ordering=-id`);

export const getSpecificLoanInfo = (id) => axiosInstance.get(`api/v1/lead-app/loan-info/${id}`);


