import { useDispatch, useSelector } from "react-redux";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import React, { useState } from "react";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import { getReport } from "../Redux/thunk";
import Modal from "../../../Components/Modal/Modal";
import ReportPage from "./ReportPage";

const CustomerListing = ({ postsPerPage, setPostsPerPage, setSearch }) => {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.report.report);
  const [showModal, setShowModal] = useState(false);

  const { customers, next } = useSelector((state) => state.report);
  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  return (
    <>
      {postsPerPage === 20 && customers ? (
        <ListingSkeleton />
      ) : (
        <div className="flex-grow-1 w-100 p-0 m-0 " style={{ overflow: "auto" }}>
          <div className=" table-scrollable w-100 p-0 mt-2" onScroll={handleScroll}>
            <table className="">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>CIF</th>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  <th>Loan Amount</th>
                  <th>View Report</th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer, index) => {
                  const { id, cif, customerName, customerEmail, loanAmount } = customer;
                  return (
                    <React.Fragment key={index}>
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{cif ? cif : "N/A"}</td>
                        <td>{customerName || "N/A"}</td>
                        <td>{customerEmail || "N/A"}</td>
                        <td>{loanAmount || "N/A"}</td>
                        <td>
                          <DetailActionButton
                            type={"view"}
                            onClick={() => {
                              setShowModal(true);
                              dispatch(getReport(cif));
                            }}
                          />{" "}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {showModal && report && (
        <Modal size={"modal-xl"} showModal={showModal} setShowModal={setShowModal} dispatch={setShowModal} header={"View Report"}>
          <ReportPage data={report} />
        </Modal>
      )}
    </>
  );
};
export default CustomerListing;
