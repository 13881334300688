import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { successFunction, errorFunction } from "../../../Components/Alert/Alert";
import { createCurrency, updateCurrency, getCurrency, deletePhoto } from "../Redux/thunk";
import { checkRedundantDataCurrency } from "../../../Utils/RedundantData/Currency";
import Loader from "../../../Components/Loader";
import TextField from "../../../Components/TextField/TextField";
import Button from "../../../Components/Buttons/Button";
import Checkbox from "../../../Components/CommonCheckbox/Checkbox";
import { closeModal } from "../../../Redux/Layout/layoutSlice";

const CreateCurrency = ({ dispatch, postsPerPage, setShowCountryModal }) => {
  const currency = useSelector((state) => state.currency.currency);
  const loading = useSelector((state) => state.currency.loading);
  const loadingUpdated = useSelector((state) => state.currency.loadingUpdated);
  const edit = useSelector((state) => state.currency.edit);

  const [lock, setLock] = useState(false);

  const initialState = {
    name: edit ? currency?.name : "",
    symbol: edit ? currency?.symbol : "",
    code: edit ? currency?.code : "",
    active: edit ? currency?.active : true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required!")
      .min(1, "Name must be at least 1 character.")
      .max(50, "Name must be at least 50 characters."),
    code: Yup.string().max(3, "Code must be maximum 3 characters."),
    symbol: Yup.string().max(3, "Symbol must be maximum 3 characters."),
    active: Yup.bool(),
  });

  const onSubmit = (values) => {
    const { name } = values;
    const capitalName = name?.charAt(0).toUpperCase() + name?.slice(1);
    if (!edit) {
      dispatch(createCurrency({ ...values, name: capitalName }))
        .unwrap()
        .then(() => {
          successFunction("Currency created successfully.");
          dispatch(getCurrency(postsPerPage));
          setShowCountryModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    } else {
      let updateData = {
        id: currency?.id,
        values: { ...values, name: capitalName },
      };
      dispatch(updateCurrency(updateData))
        .unwrap()
        .then(() => {
          successFunction("Currency updated successfully.");
          dispatch(getCurrency(postsPerPage));
          setShowCountryModal(false);
        })
        .catch((error) => errorFunction(error));
    }
  };

  // function which checks whether the bank is added already or not
  const handleChangeName = async (e) => {
    if (edit && e.target.value !== currency?.name) {
      if (e.target.value.trim() && e.target.value !== "") {
        const result = await checkRedundantDataCurrency(e, "name");
        result
          ? errorFunction("Currency with this name  is already added. ") || setLock(true)
          : setLock(false);
      }
    }
    if (e.target.value.trim() && e.target.value !== "") {
      const result = await checkRedundantDataCurrency(e, "name");
      result
        ? errorFunction("Currency with this name is already added ") || setLock(true)
        : setLock(false);
    }
  };
  return (
    <>
      {(loading || loadingUpdated) && <Loader />}

      <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="create-currency-wrapper">
                <div className="row">
                  <div className="col-12 ">
                    <TextField
                      type="text"
                      name="name"
                      label="Name"
                      required
                      formikRequired={formik?.errors?.name && formik?.touched?.name}
                      placeholder="Name"
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                        handleChangeName(e);
                      }}
                      autoFocus={true}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      type="text"
                      name="code"
                      label="Code"
                      placeholder="Code"
                      onChange={(e) => {
                        formik.setFieldValue("code", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 ">
                    <TextField
                      type="text"
                      name="symbol"
                      label="Symbol"
                      placeholder="Symbol"
                      onChange={(e) => {
                        formik.setFieldValue("symbol", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 my-2 d-flex justify-content-center align-items-center">
                  <Checkbox name="active" label="Active" />
                </div>
              </div>
              <div className="col-12 p-0 text-right">
                <div className="mt-3 d-flex justify-content-end align-items-center">
                  <Button
                    btnType="submit"
                    className="btn create-button"
                    createButton={true}
                    disabled={edit ? lock || loadingUpdated : loading || lock}
                    title={"Save"}
                    content={"Save"}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateCurrency;
