import React, { useRef } from "react";
import ColumnResize from "react-table-column-resizer";
import { useSelector } from "react-redux";
import { getSpecificUser } from "../Redux/thunk";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import Status from "../../../Components/Status";
import { userEditSuccess } from "../Redux/userSlice";

const User = ({ dispatch, setPostsPerPage, setShowUserModal }) => {
  const listRef = useRef(null);
  const next = useSelector((state) => state.user.next);
  const users = useSelector((state) => state.user.users);
  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const updateUserPermission = permissions.includes("update_user");
  const updatePermission = isSuperuser || updateUserPermission;
  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight === event.currentTarget.scrollHeight) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  const handleEdit = async (id) => {
    dispatch(userEditSuccess(id));
    await dispatch(getSpecificUser(id));
    setShowUserModal(true);
  };

  return (
    <div className="row">
      <div className="col-12 table-scrollable mt-2" onScroll={handleScroll} ref={listRef}>
        <table className="listing-table">
          <thead>
            <tr>
              <th>SN</th>
              <ColumnResize id={1} className="columnResizer" />
              <th>User Name</th>
              <ColumnResize id={2} className="columnResizer" minWidth={120} />
              <th>Full Name</th>
              <ColumnResize id={3} className="columnResizer" minWidth={120} />
              <th>Email</th>
              <ColumnResize id={4} className="columnResizer" minWidth={120} />
              <th>User Group</th>
              <ColumnResize id={4} className="columnResizer" minWidth={120} />
              <th>Departments</th>
              <ColumnResize id={4} className="columnResizer" minWidth={120} />
              <th>Branch</th>
              <ColumnResize id={4} className="columnResizer" minWidth={120} />
              <th>Status</th>
              <ColumnResize id={5} className="columnResizer" minWidth={120} />
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, i) => {
              const { id, userName, fullName, email, groups, departments, isActive, hierarchy } = user;

              return (
                <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                  <td>{i + 1}</td>
                  <td className="column_resizer_body" />
                  <td>{userName ? userName : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{fullName ? fullName : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{email ? email : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>
                    {groups?.length === 0
                      ? "-"
                      : groups?.map((group) => {
                          return (
                            <span className="badge badge-info mr-2 p-1" key={group.id}>
                              {group?.name}
                            </span>
                          );
                        })}
                  </td>
                  <td className="column_resizer_body" />
                  <td>
                    {departments.length === 0
                      ? "-"
                      : departments?.map((department) => {
                          return (
                            <span className="badge badge-info mr-2 p-1" key={department?.id}>
                              {department?.name}
                            </span>
                          );
                        })}
                  </td>
                  <td className="column_resizer_body" />
                  <td>{hierarchy !== null ? hierarchy?.name : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>
                    <Status active={isActive} />
                  </td>
                  <td className="column_resizer_body" />
                  <td>{updatePermission && <DetailActionButton type={"edit"} onClick={() => handleEdit(id)} />}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default User;
