import React, { useState, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import CommonCreateButton from "../../../Components/CommonCreateButton/CommonCreateButton";
import { getBusinessNature, handleSearch } from "../Redux/thunk";
import CreateBusinessNature from "./CreateBusinessNature";
import BusinessNatureListing from "./BusinessNatureListing";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "Business Nature";
const types = "business-nature";

const BusinessNature = () => {
  const dispatch = useDispatch();
  const loadingBusinessNature = useSelector((state) => state.businessNature.loadingBusinessNature);
  const businessNatures = useSelector((state) => state.businessNature.businessNatures);
  const count = useSelector((state) => state.businessNature.count);
  const edit = useSelector((state) => state.businessNature.edit);
  const { showModal } = useSelector((state) => state.layout);
  const [showBusinessNatureModal, setShowBusinessNatureModal] = useState(false);

  const [search, setSearch] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(20);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search === "") {
      dispatch(getBusinessNature(postsPerPage));
    } else {
      dispatch(handleSearch({ search, postsPerPage }));
    }
  }, [postsPerPage, debouncedSearch]);

  return (
    <>
      <div className="department-wrapper">
        <CommonPageHeader
          title={title}
          dispatch={dispatch}
          showModal={showModal}
          search={search}
          setSearch={setSearch}
          loading={loadingBusinessNature}
          data={businessNatures}
          count={count}
        />

        {loadingBusinessNature && <ListingSkeleton />}
        {!loadingBusinessNature && (
          <BusinessNatureListing
            dispatch={dispatch}
            showModal={showModal}
            setPostsPerPage={setPostsPerPage}
            setBusinessNatureModal={setShowBusinessNatureModal}
          />
        )}
      </div>
      <CommonCreateButton types={types} showModal={showBusinessNatureModal} setShowModal={setShowBusinessNatureModal} />
      {showBusinessNatureModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            dispatch={setShowModal}
            showModal={showBusinessNatureModal}
            setShowModal={setShowBusinessNatureModal}
            header={edit ? "Update Business Nature" : "Add Business Nature"}
            types={types}
            edit={edit}
            size={"modal-md"}
            // clearAction={clearAllDepartment}
          >
            <CreateBusinessNature
              dispatch={dispatch}
              showModal={showBusinessNatureModal}
              postsPerPage={postsPerPage}
              setShowModal={setShowBusinessNatureModal}
            />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default BusinessNature;
