import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDetails, getEventDetails } from "./thunk";
import { event } from "jquery";

const initialState = {
  dashboardDetails: [],
  eventDetails: [],
  loading: false,
  error: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardDetails: (state, action) => {
      state.dashboardDetails = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDashboardDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardDetails = action.payload;
    });
    builder.addCase(getDashboardDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEventDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.eventDetails = action.payload?.results;
    });
    builder.addCase(getEventDetails.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setDashboardDetails } = dashboardSlice.actions;
export default dashboardSlice.reducer;
