import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

export const getBusinessNature = createAsyncThunk(
  "facility/getBusinessNature",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getBusinessNature(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Business Nature.");
    }
  }
);

export const getAllBusinessNature = createAsyncThunk(
  "facility/getAllBusinessNature",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllBusinessNature();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Business Nature.");
    }
  }
);
export const getSpecificBusinessNature = createAsyncThunk(
  "facility/getSpecificBusinessNature",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificBusinessNature(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get BusinessNature type");
    }
  }
);

export const getPrevious = createAsyncThunk(
  "facility/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Business Nature.");
    }
  }
);

export const getNext = createAsyncThunk("facility/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Business Nature.");
  }
});

export const createBusinessNature = createAsyncThunk(
  "facility/createBusinessNature",
  async (data, { rejectWithValue, dispatch }) => {
    const { name } = data;
    try {
      const body = JSON.stringify({ name });
      const { data } = await API.createBusinessNature(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create facility.");
    }
  }
);
export const updateBusinessNature = createAsyncThunk(
  "facility/updateBusinessNature",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, name } = data;

    try {
      const body = JSON.stringify({ name });
      const { data } = await API.updateBusinessNature(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create facility.");
    }
  }
);
export const deleteBusinessNature = createAsyncThunk(
  "facility/deleteBusinessNature",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.deleteBusinessNature(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to Delete facility.");
    }
  }
);

export const handleSearch = createAsyncThunk(
  "facility/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get facility");
    }
  }
);
