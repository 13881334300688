import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

// Async Thunk for Annexure
export const getAllCollectionAndRecovery = createAsyncThunk("collecitonAndRecovery/getAllCollectionAndRecovery", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllCollectionAndRecovery();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCollectionAndRecovery = createAsyncThunk(
  "collecitonAndRecovery/getCollectionAndRecovery",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getCollectionAndRecovery(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const handleSearch = createAsyncThunk("collecitonAndRecovery/handleSearch", async (value, { rejectWithValue }) => {
  const { search, postsPerPage } = value;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue("Could not find data");
  }
});
