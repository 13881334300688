import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { successFunction, errorFunction } from "../../../Components/Alert/Alert";
import { createUser, updateUser, getUser, deletePhoto } from "../Redux/thunk";
import { checkRedundantDataUser } from "../../../Utils/RedundantData/User";
import Thumb from "../../../Components/Thumb";
import { SUPPORTED_FORMATS } from "../../../Utils/image";
import { dateFormater } from "../../../Utils/dateFormater";
import axiosInstance from "../../../Utils/axios";
import { genders } from "../../../Utils/constants";
import Loader from "../../../Components/Loader";
import DatePicker from "../../../Components/CommonDatePicker/DatePicker";
import Textarea from "../../../Components/CommonTextarea/Textarea";
import Dropzone from "../../../Components/CommonDropzone/Dropzone";
import Select from "../../../Components/CommonSelectField/Select";
import AsyncSelect from "../../../Components/CommonAsyncSelectField/AsyncSelect";
import TextField from "../../../Components/TextField/TextField";
import Button from "../../../Components/Buttons/Button";
import Checkbox from "../../../Components/CommonCheckbox/Checkbox";

const CreateUser = ({ dispatch, setShowModal, postsPerPage }) => {
  const user = useSelector((state) => state.user.user);
  const newUserGroup = useSelector((state) => state.userGroup.userGroup);
  const loading = useSelector((state) => state.user.loading);
  const loadingUpdated = useSelector((state) => state.user.loadingUpdated);
  const edit = useSelector((state) => state.user.edit);
  const [lock, setLock] = useState(false);
  const [data, setData] = useState(null);
  const [img, setImg] = useState(null);
  const [password, setPassword] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("password");
  const initialState = {
    fullName: data !== null ? data?.fullName : edit ? user?.fullName : "",
    email: data !== null ? data.email : edit ? user?.email : "",
    userName: data !== null ? data.userName : edit ? user?.userName : "",
    address: data !== null ? data.address : edit ? user?.address : "",
    mobileNo: data !== null ? data.mobileNo : edit ? user?.mobileNo : "",
    birthDate: edit ? (user?.birthDate !== "" ? new Date(user?.createdAtAd) : new Date()) : new Date(),
    gender:
      data !== null
        ? data.gender
        : edit
        ? user?.gender
          ? genders.find((gender) => gender.id === user?.gender)
          : ""
        : "",
    photo: "",
    departments: edit ? user?.departments : [],
    hierarchy: edit ? user?.hierarchy : null,
    groups: newUserGroup !== null ? newUserGroup.id : edit ? user?.groups : [],
    password: data !== null ? data.password : edit ? user?.password : "",
    confirmPassword: data !== null ? data.confirmPassword : edit ? user?.confirmPassword : "",
    isActive: edit ? user?.isActive : true,
    remarks: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Required")
      .min(2, "Fullname must be at least 2 characters.")
      .max(50, "Fullname should be 50 characters.")
      .matches(/(?=.*^[A-Za-z]\w).*$/, "Fullname should only contain alphabet."),
    email: Yup.string().email("@ is required").required("Required."),
    userName: Yup.string()
      .required("Required.")
      .min(4, "Username must be at least 4 characters.")
      .max(50, "Username should be 50 characters.")
      .matches(/(?=.*^[A-Za-z_]\w).*$/, "Username should begin with _ or alphabet."),
    password: edit
      ? Yup.string()
      : Yup.string()
          .required("Required.")
          .min(6, "Password must be at least 6 characters.")
          .matches(
            /^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/,
            "Must Contain 6 Characters, One alphabet and One Number "
          ),
    confirmPassword: edit
      ? Yup.string()
      : Yup.string()
          .required("Required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match."),
    photo: Yup.mixed()
      .test(
        "fileSize",
        "File Size is too large, max file size is 500 KB.",
        (file) => !file || (file && file.size <= 500 * 1024)
      )
      .test("fileType", "Unsupported File Format.", (file) => !file || (file && SUPPORTED_FORMATS.includes(file.type))),
    address: Yup.string().min(3, "Address must be at least 3 characters.").max(50, "Address must be 50 characters."),
    mobileNo: Yup.string().matches(/^[9]\d{9}$/, "Mobile number should start with 98 and should be 10 digits."),
    birthDate: Yup.date()
      .max(new Date(), "Date of birth can not be greater than today's date.")
      .required("Birthday is required"),
    groups: Yup.array().min(1, "User should have minimum one group").required("Required"),
    // departments: Yup.array().min(1, "User should have minimum one department").required("Required"),
    hierarchy: Yup.object().required("Required!"),
    isActive: Yup.bool(),
    gender: Yup.object().nullable(),
    remarks: edit ? Yup.string().required("Required") : Yup.string(),
  });
  const onSubmit = (values) => {
    const {
      fullName,
      email,
      userName,
      address,
      mobileNo,
      photo,
      groups,
      departments,
      isActive,
      gender,
      birthDate,
      password,
      confirmPassword,
      remarks,
      hierarchy,
    } = values;
    const formattedDate = birthDate ? dateFormater(birthDate) : "";
    const capitalFullName = fullName?.charAt(0).toUpperCase() + fullName?.slice(1);
    const capitalAddress = address?.charAt(0).toUpperCase() + address?.slice(1);
    const updatedGroups = groups.map((data) => data.id);
    const updatedDepartments = departments.map((data) => data.id);
    if (edit) {
      const id = user?.id;
      let data = {
        id,
        values: {
          fullName: capitalFullName,
          email,
          userName: userName?.charAt(0).toLowerCase() + userName?.slice(1),
          gender: gender !== "" ? gender.id : "",
          address: capitalAddress,
          birthDate: formattedDate,
          groups: updatedGroups,
          departments: updatedDepartments,
          hierarchy: hierarchy ? hierarchy?.id : "",
          isActive,
          mobileNo,
          remarks,
          photo,
        },
      };
      dispatch(updateUser(data))
        .unwrap()
        .then(() => {
          successFunction("User updated successfully.");
          dispatch(getUser(postsPerPage));
          setData(null);
          setShowModal(false);
        })
        .catch(() => errorFunction("Failed to update user."));
    } else {
      let data = {
        fullName: capitalFullName,
        email,
        password,
        confirmPassword,
        userName: userName?.charAt(0).toLowerCase() + userName?.slice(1),
        gender: gender !== "" ? gender.id : "",
        address: capitalAddress,
        birthDate: formattedDate,
        groups: updatedGroups,
        departments: updatedDepartments,
        hierarchy: hierarchy ? hierarchy?.id : "",
        isActive,
        mobileNo,
        photo,
      };

      dispatch(createUser(data))
        .unwrap()
        .then(() => {
          successFunction("User created successfully.");
          dispatch(getUser(postsPerPage));
          setShowModal(false);
          setData(null);
        })
        .catch(() => errorFunction("Failed to create user."));
    }
  };
  const handleDelete = () => {
    dispatch(deletePhoto(user.id));
  };
  const handleChange = async (e) => {
    if (edit && e.target.value !== user?.userName) {
      if (e.target.value.trim() && e.target.value !== "") {
        const result = await checkRedundantDataUser(e);
        result ? errorFunction("User already exist.") || setLock(true) : setLock(false);
      }
    }
    if (!edit && e.target.value.trim() && e.target.value !== "") {
      const result = await checkRedundantDataUser(e);
      result ? errorFunction("Username already exist.") || setLock(true) : setLock(false);
    }
  };
  // toggle password
  const handlePasswordClick = () => {
    setPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  // toggle confirm password
  const handleConfirmClick = () => {
    setConfirmPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  const loadOptionsGroups = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(
      `/api/v1/user-app/group-list?search=${search}&offset=${offset}&limit=${limit}`
    );
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.count > offset ? offset + 10 : offset,
        limit: 10,
      },
    };
  };
  const loadOptionsDepartments = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(
      `api/v1/department-app/department?search=${search}&offset=${offset}&limit=${limit}`
    );
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.count > offset ? offset + 10 : offset,
        limit: 10,
      },
    };
  };
  const loadOptionsBranches = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(
      `api/v1/lead-app/hierarchy-list?search=${search}&offset=${offset}&limit=${limit}`
    );
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.count > offset ? offset + 10 : offset,
        limit: 10,
      },
    };
  };
  return (
    <>
      {/* {(loading || loadingUpdated) && <Loader />} */}
      <div className="create-user-wrapper">
        <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(formik) => {
            return (
              <Form autoComplete="off">
                <div className="my-2">
                  <div className="row">
                    <div className="col-2">
                      <Dropzone
                        name="photo"
                        label="Photo"
                        removePhoto={() => {
                          if (edit) {
                            handleDelete();
                          } else {
                            formik.setFieldValue("photo", "");
                            setImg(null);
                          }
                        }}
                        onChange={(event) => {
                          formik.setFieldValue("photo", event.target.files[0]);
                          let reader = new FileReader();
                          reader.readAsDataURL(event.target.files[0]);
                          reader.onloadend = () => setImg([reader.result]);
                        }}
                        displayImage={
                          img ? <Thumb thumb={img} /> : user && user.photo && !img ? <Thumb thumb={user.photo} /> : ""
                        }
                        error={formik.errors.photo}
                        text={"File must be less than 500kb"}
                      />
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-4">
                          <div className="my-2">
                            <TextField
                              type="text"
                              name="fullName"
                              label="Full Name"
                              required
                              formikRequired={formik?.errors?.fullName && formik?.touched?.fullName}
                              placeholder=" First Name"
                              onChange={(e) => {
                                formik.setFieldValue("fullName", e.target.value);
                              }}
                              autoFocus={true}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <TextField
                              type="text"
                              name="userName"
                              label="User Name"
                              required
                              formikRequired={formik?.errors?.userName && formik?.touched?.userName}
                              placeholder=" User Name"
                              onChange={(e) => {
                                const val = (e.target.value || "").replace(/\s+/gi, "");
                                formik.setFieldValue("userName", val.trim());
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <TextField
                              type="text"
                              name="email"
                              label="Email"
                              required
                              formikRequired={formik?.errors?.email && formik?.touched?.email}
                              placeholder=" Email"
                              onChange={(e) => {
                                formik.setFieldValue("email", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <TextField
                              type="text"
                              name="address"
                              label="Address"
                              placeholder=" Address"
                              onChange={(e) => {
                                formik.setFieldValue("address", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <DatePicker
                              name="birthDate"
                              label="Birth Date"
                              required={true}
                              maxDate={new Date()}
                              selected={formik.values.birthDate}
                              onChange={(date) => formik.setFieldValue("birthDate", date)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <TextField
                              type="number"
                              name="mobileNo"
                              label="Mobile No"
                              placeholder="Mobile No"
                              onChange={(e) => {
                                formik.setFieldValue("mobileNo", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <AsyncSelect
                              value={formik.values.groups}
                              name="groups"
                              label="User Group(s)"
                              isMulti={true}
                              required={true}
                              formikRequired={formik?.errors?.groups && formik?.touched?.groups}
                              getOptionLabel={(option) => `${option?.name} `}
                              getOptionValue={(option) => `${option?.id}`}
                              onChange={(selected) => {
                                formik.setFieldValue("groups", selected);
                              }}
                              loadOptions={loadOptionsGroups}
                              additional={{
                                offset: 0,
                                limit: 10,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <AsyncSelect
                              value={formik.values.hierarchy}
                              name="hierarchy"
                              label="Branch"
                              required={true}
                              formikRequired={formik?.errors?.hierarchy && formik?.touched?.hierarchy}
                              getOptionLabel={(option) => `${option?.name} `}
                              getOptionValue={(option) => `${option?.id}`}
                              onChange={(selected) => {
                                formik.setFieldValue("hierarchy", selected);
                              }}
                              loadOptions={loadOptionsBranches}
                              additional={{
                                offset: 0,
                                limit: 10,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <AsyncSelect
                              value={formik.values.departments}
                              name="departments"
                              label="Department(s)"
                              isMulti={true}
                              // required={true}
                              formikRequired={formik?.errors?.departments && formik?.touched?.departments}
                              getOptionLabel={(option) => `${option?.name} `}
                              getOptionValue={(option) => `${option?.id}`}
                              onChange={(selected) => {
                                formik.setFieldValue("departments", selected);
                              }}
                              loadOptions={loadOptionsDepartments}
                              additional={{
                                offset: 0,
                                limit: 10,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="my-2">
                            <Select
                              value={formik.values.gender}
                              name="gender"
                              label="Gender"
                              options={genders}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onChange={(selected) => {
                                formik.setFieldValue("gender", selected);
                              }}
                            />
                          </div>
                        </div>
                        {!edit && (
                          <>
                            <div className="col-4 password-field">
                              <div className="my-2" style={{ position: "relative" }}>
                                <TextField
                                  type={password}
                                  name="password"
                                  label="Password"
                                  required
                                  formikRequired={formik?.errors?.password && formik?.touched?.password}
                                  placeholder="Password"
                                  onChange={(e) => {
                                    formik.setFieldValue("password", e.target.value);
                                  }}
                                />
                                <span className="fa-eye-button" onClick={handlePasswordClick}>
                                  {password === "password" ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                </span>
                              </div>
                            </div>
                            <div className="col-4 password-field">
                              <div className="my-2" style={{ position: "relative" }}>
                                <TextField
                                  type={confirmPassword}
                                  name="confirmPassword"
                                  label="Confirm Password"
                                  required
                                  formikRequired={formik?.errors?.confirmPassword && formik?.touched?.confirmPassword}
                                  placeholder="Confirm Password"
                                  onChange={(e) => {
                                    formik.setFieldValue("confirmPassword", e.target.value);
                                  }}
                                />
                                <span className="fa-eye-button" onClick={handleConfirmClick}>
                                  {confirmPassword === "password" ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {edit && (
                        <div className="col-4 p-0">
                          <div className="my-2">
                            <Textarea
                              name="remarks"
                              label="Remarks"
                              required
                              onChange={(e) => {
                                formik.setFieldValue("remarks", e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="my-2 d-flex justify-content-center align-items-center">
                  <Checkbox name="isActive" label="Active" edit={edit} />
                </div>
                <div className="my-4 d-flex justify-content-end align-items-center">
                  <Button
                    btnType="submit"
                    className="btn create-button"
                    createButton={true}
                    disabled={edit ? lock || loadingUpdated : lock || loading || !formik.dirty}
                    title={edit ? "Update" : "Save"}
                    content={edit ? "Update" : "Save"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
export default CreateUser;
