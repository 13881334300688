import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAnnex,
  getAllQuestions,
  getAnnex,
  getAnswer,
  getQuestion,
  getQuestionAnswer,
  getSubQuestion,
  handleSearch,
} from "./thunk.js";

const initialState = {
  annexures: [],
  annexure: null,
  questions: [],
  question: null,
  answers: [],
  answer: "",
  questionAnswers: [],
  subQuestion: null,
  edit: false,
  editQuestion: false,
  loading: false,
  previous: null,
  next: null,
  loadingUpdated: false,
};

export const annexSlice = createSlice({
  name: "annex",
  initialState,
  reducers: {
    editAnnex: (state) => {
      state.edit = true;
    },
    clearEditAnnexure: (state, action) => {
      state.edit = false;
      state.question = null;
      state.editQuestion = false;
    },

    editQuestion: (state) => {
      state.editQuestion = true;
    },
  },
  extraReducers: (builder) => {
    // additional reducers for annexure
    builder.addCase(getAnnex.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAnnex.fulfilled, (state, action) => {
      state.loading = false;
      state.annexure = action?.payload;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getAnnex.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for all annexure
    builder.addCase(getAllAnnex.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAnnex.fulfilled, (state, action) => {
      state.loading = false;
      state.annexures = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getAllAnnex.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for a question
    builder.addCase(getQuestion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.question = action.payload;
    });

    builder.addCase(getQuestion.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for all questions
    builder.addCase(getAllQuestions.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.questions = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getAllQuestions.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for answer
    builder.addCase(getAnswer.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.answers = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getAnswer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.questions = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for question answer
    builder.addCase(getQuestionAnswer.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getQuestionAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.questionAnswers = action.payload.questions;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getQuestionAnswer.rejected, (state) => {
      state.loading = false;
    });

    // additional reducers for question answer
    builder.addCase(getSubQuestion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSubQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.subQuestion = action.payload;
    });

    builder.addCase(getSubQuestion.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { editAnnex, clearEditAnnexure, editQuestion } = annexSlice.actions;
export default annexSlice.reducer;
