import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get department
export const getDepartment = createAsyncThunk(
  "department/getDepartment",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getDepartment(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get fiscal year.");
    }
  }
);

// get all fiscal year
export const getAllDepartment = createAsyncThunk(
  "department/getAllDepartment",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllDepartment();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get fiscal year.");
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "department/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get fiscal year.");
    }
  }
);
// get next
export const getNext = createAsyncThunk(
  "department/getNext",
  async (next, { rejectWithValue }) => {
    try {
      const { data } = await API.getNext(next);

      return data;
    } catch (error) {
      return rejectWithValue("Failed to get fiscal year.");
    }
  }
);

// get particular page
export const getPageDepartment = createAsyncThunk(
  "department/getPageDepartment",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDepartment(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get fiscal year.");
    }
  }
);

// create department
export const createDepartment = createAsyncThunk(
  "department/createDepartment",
  async (data, { rejectWithValue, dispatch }) => {
    const { name, code, email, active } = data;
    try {
      const body = JSON.stringify({ name, code, email, active });
      const { data } = await API.createDepartment(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create department.");
    }
  }
);
export const updateDepartment = createAsyncThunk(
  "department/updateDepartment",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, values } = data;
    const { name, code, email, active } = values;
    try {
      const body = JSON.stringify({ name, code, email, active });
      const { data } = await API.updateDepartment(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update department.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "department/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get department");
    }
  }
);
