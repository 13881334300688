import React, { useState, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import CommonCreateButton from "../../../Components/CommonCreateButton/CommonCreateButton";
import FacilitiesListing from "./FacilitiesListing";
import { getFacility, handleSearch } from "../Redux/thunk";
import CreateFacilities from "./CreateFacilities";
import { leadClearData } from "../../Leads/Redux/leadsSlice";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "Facility Type";
const types = "facility-type";

const Facilities = () => {
  const dispatch = useDispatch();
  const loadingFacility = useSelector((state) => state.facility.loadingFacility);
  const facilities = useSelector((state) => state.facility.facilities);
  const count = useSelector((state) => state.facility.count);
  const edit = useSelector((state) => state.facility.edit);
  const { showModal } = useSelector((state) => state.layout);
  const [showFacilitiesModal, setShowFacilitiesModal] = useState(false);

  const [search, setSearch] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(10);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search === "") {
      dispatch(getFacility(postsPerPage));
    } else {
      dispatch(handleSearch({ search, postsPerPage }));
    }
  }, [postsPerPage, debouncedSearch]);

  return (
    <>
      <div className="department-wrapper">
        <CommonPageHeader
          title={title}
          dispatch={dispatch}
          showModal={showModal}
          search={search}
          setSearch={setSearch}
          loading={loadingFacility}
          data={facilities}
          count={count}
        />

        {loadingFacility && <ListingSkeleton />}
        {!loadingFacility && (
          <FacilitiesListing
            dispatch={dispatch}
            showModal={showModal}
            setPostsPerPage={setPostsPerPage}
            setFacilitiesModal={setShowFacilitiesModal}
          />
        )}
      </div>
      <CommonCreateButton types={types} showModal={showFacilitiesModal} setShowModal={setShowFacilitiesModal} />
      {showFacilitiesModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            dispatch={setShowModal}
            showModal={showFacilitiesModal}
            setShowModal={setShowFacilitiesModal}
            header={edit ? "Update Facility" : "Add Facility"}
            types={types}
            edit={edit}
            size={"modal-md"}
            clearAction={leadClearData}
            // clearAction={clearAllDepartment}
          >
            <CreateFacilities dispatch={dispatch} showModal={showFacilitiesModal} postsPerPage={postsPerPage} setShowModal={setShowFacilitiesModal} />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default Facilities;
