import { AiOutlineHolder } from "react-icons/ai";
import ListingSkeleton from "../../../../Components/Skeleton/ListingSkeleton";
import React, { useRef, useState } from "react";
import DetailActionButton from "../../../../Components/Icons/DetailButtonIcon";
import ColumnResize from "react-table-column-resizer";
import { useDispatch, useSelector } from "react-redux";
import { getLoanInfo } from "../../../Loan/Pages/ViewDetails/Redux/thunk";
import { dateFormater } from "../../../../Utils/dateFormater";
import { all } from "axios";

const InspectionTab = ({ loanType, setLoanType, json, collateral, inspection, postsPerPage, setPostsPerPage }) => {
  const listInnerRef = useRef();
  const { next } = useSelector((state) => state.leadDetail);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const viewPermission = permissions.includes("view_loan_info") || isSuperuser;

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };
  const getAllLoans = (json) => {
    let loans = [];
    json?.accounts?.forEach((account) => {
      account?.loans?.forEach((loan) => {
        loans.push(loan);
      });
    });
    return loans;
  };
  const allLoans = getAllLoans(json);
  const getAllCollateral = () => {
    let collaterals = [];
    allLoans?.map((loans) => {
      loans?.collateral?.forEach((collateral) => {
        collaterals.push(collateral);
      });
    });
    return collaterals;
  };
  const allCollaterals = getAllCollateral();
  const getAllInspections = () => {
    let inspections = [];
    allCollaterals?.map((collaterals) => {
      collaterals?.inspection?.forEach((inspection) => {
        inspections.push(inspection);
      });
    });
    return inspections;
  };
  const allInspections = getAllInspections();

  return (
    <>
      {postsPerPage === 20 ? (
        <ListingSkeleton />
      ) : (
        <div className=" p-0 m-0 " style={{ overflow: "auto" }}>
          <div className=" table-scrollable mt-2" onScroll={handleScroll} ref={listInnerRef}>
            <p className="m-0 mb-0" style={{ color: "#008eb0", fontSize: "12px", fontWeight: "500" }}>
              Inspections
            </p>
            <table style={{ borderSpacing: "0px 5px" }} className="">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Application No</th>
                  <th>Date of Inspection</th>
                  <th>Nature of Inspection</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loanType === "All"
                  ? allInspections?.map((ins, index) => {
                      const {
                        id,
                        collateral_LoanInfo_ApplicationNo,
                        dateOfInspection,
                        natureOfInspection,
                        area,
                        status,
                      } = ins;
                      return (
                        <React.Fragment key={id}>
                          <tr key={id}>
                            <td>{index + 1}</td>
                            <td>{collateral_LoanInfo_ApplicationNo || "N/A"}</td>
                            <td>{dateFormater(dateOfInspection) || "N/A"}</td>
                            <td>{natureOfInspection || "N/A"}</td>
                            <td>
                              {status && status === 1
                                ? "TO BE INSPECTED"
                                : status === 2
                                ? "INSPECTED"
                                : "INSPECTION APPROVED"}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                  : "hELLO"}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
export default InspectionTab;
