import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSystemSelected } from "../Redux/SystemSelection/systemSelectionSlice";
import PageNotFound from "../Components/PageNotFound";
import ProtectedRoute from "./ProtectedRoute";
import permission from "./permission.json";
import { closeModal } from "../Redux/Layout/layoutSlice";
import CustomerDetails from "../Pages/CustomerDetails/Pages";
import User from "../Pages/User/Page";
import BusinessNature from "../Pages/BusinessNature/Pages";
import CollateralType from "../Pages/CollateralType/Pages";
import Facilities from "../Pages/Facilities/Pages";
import UserGroup from "../Pages/UserGroup/Page";
import FiscalSessionAD from "../Pages/FiscalSessionAD/Page";
import FiscalSessionBS from "../Pages/FiscalSessionBS/Page";
import Currency from "../Pages/Currency/Page";
import Country from "../Pages/Country/Page";
import Organization from "../Pages/Organization/Page";
import Dashboard from "../Pages/Dashboard/Pages";
// import Dashboard from "../Pages/Dashboard/Pages";

const Leads = lazy(() => import("../Pages/Leads/Pages"));
const DepartmentListing = lazy(() => import("../Pages/Department/Page"));
const CoreSetupTabs = lazy(() => import("../Components/Tab/CoreSetupTab"));
const LoanSetupTabs = lazy(() => import("../Components/Tab/LoanSetupTab"));
const UserTabs = lazy(() => import("../Components/Tab/UserTab"));
const HierarchyListing = lazy(() => import("../Pages/OrganizationHierarchy/Page"));
const ChangePassword = lazy(() => import("../Pages/User/ResetPassword/ChangePassword"));

const Inspection = lazy(() => import("../Pages/Inspection/Pages"));
const Loan = lazy(() => import("../Pages/Loan/Pages"));
const AnnexTab = lazy(() => import("../Components/Tab/CollateralQuestionnaireTab"));
const BusinessAnnexTab = lazy(() => import("../Components/Tab/BusinessQuestionnaireTab"));
const CollectionAndRecovery = lazy(() => import("../Pages/CollectionAndRecovery/Page"));

const PrivateRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Your function to be called on page refresh
    const handleRefresh = () => {
      dispatch(closeModal());

      // Add your logic here
    };

    // Attach event listener for the 'beforeunload' event
    window.addEventListener("beforeunload", handleRefresh);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [dispatch]);

  return (
    <Suspense fallback={""}>
      <Switch>
        <Layout>
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} permission={""} />
            <ProtectedRoute exact path="/leads" component={Leads} permission={permission?.lead} />
            <ProtectedRoute exact path="/inspection" component={Inspection} permission={permission?.inspection} />
            <ProtectedRoute exact path="/client-registration" component={Loan} permission={permission?.loan} />
            <ProtectedRoute exact path="/collateral-questionnaires" component={AnnexTab} permission={""} />
            <ProtectedRoute exact path="/business-questionnaires" component={BusinessAnnexTab} permission={""} />
            <ProtectedRoute
              path="/collection-and-recovery"
              component={CollectionAndRecovery}
              permission={permission?.collectionAndRecovery}
            />
            <ProtectedRoute path="/customer-details" component={CustomerDetails} permission={""} />
            <ProtectedRoute
              exact
              path="/department"
              component={DepartmentListing}
              permission={permission?.department}
            />
            <ProtectedRoute exact path="/core-setup" component={CoreSetupTabs} permission={permission?.coreSetup} />
            <ProtectedRoute exact path="/loan-setup" component={LoanSetupTabs} permission={permission?.loanSetup} />
            <ProtectedRoute exact path="/user-setup" component={UserTabs} permission={permission?.userSetup} />
            <ProtectedRoute exact path="/hierarchy" component={HierarchyListing} permission={permission?.hierarchy} />
            <ProtectedRoute
              exact
              path="/change-password"
              component={ChangePassword}
              permission={permission?.changePassword}
            />
            <ProtectedRoute
              path="/collection-and-recovery"
              component={CollectionAndRecovery}
              permission={permission?.collectionAndRecovery}
            />
            <ProtectedRoute path="/customer-details" component={CustomerDetails} permission={""} />
            <ProtectedRoute path="/fiscalSessionBS" component={FiscalSessionBS} permission={""} />
            <ProtectedRoute path="/fiscalSessionAD" component={FiscalSessionAD} permission={""} />
            <ProtectedRoute path="/currency" component={Currency} permission={""} />
            <ProtectedRoute path="/country" component={Country} permission={""} />
            <ProtectedRoute path="/organization-setup" component={Organization} permission={""} />
            <ProtectedRoute path="/user" component={User} permission={""} />
            <ProtectedRoute path="/user-group" component={UserGroup} permission={""} />
            <ProtectedRoute path="/facility-type" component={Facilities} permission={""} />
            <ProtectedRoute path="/business-nature" component={BusinessNature} permission={""} />
            <ProtectedRoute
              path="/collateral-type"
              component={CollateralType}
              permission={permission?.collateralType}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Layout>
        )
      </Switch>
    </Suspense>
  );
};

export default PrivateRoute;
