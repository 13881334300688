import { createSlice } from "@reduxjs/toolkit";
import {
  createBSIR,
  createCollateral,
  createGuarantor,
  createLoanInfo,
  getAllLoanInfo,
  getBSIR,
  getCollateralType,
  getGuarantor,
  getLoanInfo,
  getSpecificBankRelation,
  getSpecificBSIR,
  getSpecificCollateral,
  getSpecificCollateralInspection,
  getSpecificFacility,
  getSpecificGuarantor,
  getSpecificInspection,
  getSpecificLeadInspection,
  getSpecificLoanInfoBankRelation,
  getSpecificLoanInfoCollateral,
  getSpecificLoanInfoGuarantor,
  getSpecificLoanInfoShareholder,
  getSpecificLoanInfoSisterConcerns,
  getSpecificShareholder,
  getSpecificSisterConcerns,
  handleSearch,
  updateBSIR,
} from "./thunk";

const initialState = {
  allLoanInfo: [],
  loanInfo: null,
  guarantor: null,
  loading: false,
  count: 0,
  guarantorList: [],
  bsir: [],
  editBsir: false,
  specificBSIR: null,
  facility: [],
  specificLoanInfo: null,
  previous: null,
  next: null,
  sisterConcernsList: [],
  sisterConcern: null,
  shareholderList: [],
  shareholder: null,
  edit_shareholder: false,
  bankRelationList: [],
  bankRelation: null,
  edit_banking_relation: false,
  inspection: null,
  inspectionList: [],
  collateralList: [],
  collateral: null,
  collateralTypes: [],
  collateralType: null,
  edit: false,
  editInspec: false,
  loadingLoan: false,
};

export const leadDetailsSlice = createSlice({
  name: "leadDetail",
  initialState,
  reducers: {
    loanInfoClearData: (state) => {
      state.loanInfo = null;
      state.loading = false;
      state.guarantor = null;
      state.bsir = null;
      state.sisterConcern = null;
      state.specificLoanInfo = null;
    },
    clearEditGuarantors: (state, action) => {
      state.edit = false;
      state.loading = false;
    },
    clearEditBsir: (state, action) => {
      state.editBsir = false;
      state.loading = false;
    },

    editGuarantor: (state) => {
      state.edit = true;
    },
    editSisterConcerns: (state) => {
      state.edit = true;
    },
    editFacility: (state) => {
      state.edit = true;
    },
    cancelEditSisterConcerns: (state) => {
      state.edit = false;
    },
    cancelEditGuarantor: (state) => {
      state.edit = false;
    },
    editBSIR: (state) => {
      state.editBsir = true;
    },
    editLoanInfo: (state) => {
      state.edit = true;
    },
    cancelEditBSIR: (state) => {
      state.editBsir = false;
    },
    editCollateral: (state) => {
      state.edit = true;
    },
    editInspection: (state) => {
      state.editInspec = true;
    },
    cancelEditInspection: (state) => {
      state.editInspec = false;
    },
    editBankingRelation: (state) => {
      state.edit_banking_relation = true;
    },
    clearEditBankingRelation: (state) => {
      state.edit_banking_relation = false;
    },
    editShareholder: (state) => {
      state.edit_shareholder = true;
    },
    clearEditShareholder: (state) => {
      state.edit_shareholder = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoanInfo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLoanInfo.fulfilled, (state, action) => {
      state.loanInfo = action.payload;
      state.specificLoanInfo = action.payload.results[0];
      state.loading = false;
    });

    builder.addCase(getLoanInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllLoanInfo.pending, (state) => {
      state.loadingLoan = true;
    });

    builder.addCase(getAllLoanInfo.fulfilled, (state, action) => {
      state.allLoanInfo = action.payload.results;
      state.loadingLoan = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });

    builder.addCase(getAllLoanInfo.rejected, (state) => {
      state.loadingLoan = false;
    });

    builder.addCase(getSpecificLoanInfoGuarantor.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfoGuarantor.fulfilled, (state, action) => {
      state.guarantorList = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificLoanInfoGuarantor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificGuarantor.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificGuarantor.fulfilled, (state, action) => {
      state.guarantor = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificGuarantor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createLoanInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLoanInfo.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createLoanInfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getGuarantor.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getGuarantor.fulfilled, (state, action) => {
      state.guarantor = action.payload;
      state.loading = false;
      state.count = action.payload.count;
    });

    builder.addCase(getGuarantor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createGuarantor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGuarantor.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createGuarantor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getBSIR.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getBSIR.fulfilled, (state, action) => {
      state.bsir = action.payload.results;
      state.loading = false;
      state.count = action.payload.count;
    });

    builder.addCase(getBSIR.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSpecificBSIR.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificBSIR.fulfilled, (state, action) => {
      state.specificBSIR = action.payload;
      state.loading = false;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificBSIR.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createBSIR.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBSIR.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createBSIR.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBSIR.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBSIR.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateBSIR.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificLoanInfoSisterConcerns.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfoSisterConcerns.fulfilled, (state, action) => {
      state.sisterConcernsList = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificLoanInfoSisterConcerns.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificSisterConcerns.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificSisterConcerns.fulfilled, (state, action) => {
      state.sisterConcern = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificSisterConcerns.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificLoanInfoShareholder.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfoShareholder.fulfilled, (state, action) => {
      state.shareholderList = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificLoanInfoShareholder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificShareholder.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificShareholder.fulfilled, (state, action) => {
      state.shareholder = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificShareholder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificLoanInfoBankRelation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfoBankRelation.fulfilled, (state, action) => {
      state.bankRelationList = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificLoanInfoBankRelation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificBankRelation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificBankRelation.fulfilled, (state, action) => {
      state.bankRelation = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificBankRelation.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSpecificFacility.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificFacility.fulfilled, (state, action) => {
      state.facility = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificFacility.rejected, (state) => {
      state.loading = false;
    });

    //Extra reducers for collateral type
    builder.addCase(getCollateralType.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getCollateralType.fulfilled, (state, action) => {
      state.collateralTypes = action.payload;
      state.loading = false;
    });

    builder.addCase(getCollateralType.rejected, (state) => {
      state.loading = false;
    });

    //Extra reducers for collateral
    builder.addCase(getSpecificLoanInfoCollateral.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfoCollateral.fulfilled, (state, action) => {
      state.collateralList = action.payload.results;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificLoanInfoCollateral.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificCollateral.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificCollateral.fulfilled, (state, action) => {
      state.collateral = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificCollateral.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createCollateral.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCollateral.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createCollateral.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificCollateralInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificCollateralInspection.fulfilled, (state, action) => {
      state.inspectionList = action.payload.results;
      state.loading = false;
    });

    builder.addCase(getSpecificCollateralInspection.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificLeadInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLeadInspection.fulfilled, (state, action) => {
      state.inspectionList = action.payload.results;
      state.loading = false;
    });

    builder.addCase(getSpecificLeadInspection.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificInspection.fulfilled, (state, action) => {
      state.inspection = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificInspection.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.allLoanInfo = action.payload.results;
      state.loading = false;
      state.count = action.payload.count;
    });

    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  loanInfoClearData,
  cancelEditGuarantor,
  editGuarantor,
  cancelEditBSIR,
  clearEditGuarantors,
  clearEditBsir,
  editSisterConcerns,
  editFacility,
  editCollateral,
  editInspection,
  editBSIR,
  cancelEditInspection,
  editBankingRelation,
  clearEditBankingRelation,
  editShareholder,
  clearEditShareholder,
} = leadDetailsSlice.actions;
export default leadDetailsSlice.reducer;
