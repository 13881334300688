import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getUser = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/user-app/users?offset=0&limit=${postsPerPage}&ordering=-id`
  );

//obtaining all data
export const getAllUser = () =>
  axiosInstance.get(`api/v1/user-app/users?ordering=-id`);

export const getCurrentUser = (decoded) =>
  axiosInstance.get(`api/v1/user-app/users/${decoded.user_id}`);
//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageUser = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/user-app/users?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createUser = (body) =>
  axiosInstance.post(`api/v1/user-app/register`, body, {
    "Content-Type": "multipart/form-data",
  });

//deleting function
export const deleteUser = (id) =>
  axiosInstance.delete(`api/v1/user-app/users/${id}`);

//update function
export const updateUser = (id, body) =>
  axiosInstance.patch(`api/v1/user-app/update-profile/${id}`, body, {
    "Content-Type": "multipart/form-data",
  });

// get specific user
export const getSpecificUser = (id) =>
  axiosInstance.get(`api/v1/user-app/users/${id}`);
//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/user-app/users?offset=0&limit=${postsPerPage}&search=${search}`
  );

// checking the redundant data
export const checkRedundantData = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/user-app/users?user_name=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
//deleting the image
export const deletePhoto = (id, body) =>
  axiosInstance.patch(`api/v1/user-app/users/${id}`, body);
//change password
export const changePassword = (id, body) =>
  axiosInstance.get(`api/v1/user-app/change-password/${id}`, body);
