import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getCurrency = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/core-app/currency?offset=0&limit=${postsPerPage}&ordering=-id`
  );

//obtaining all fiscal sessions
export const getAllCurrency = () =>
  axiosInstance.get(`api/v1/core-app/currency?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageCurrency = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/core-app/currency?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createCurrency = (body) =>
  axiosInstance.post(`api/v1/core-app/currency`, body);
//updating function
export const updateCurrency = (id, body) =>
  axiosInstance.patch(`api/v1/core-app/currency/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/core-app/currency?offset=0&limit=${postsPerPage}&search=${search}`
  );
// checking the redundant data
export const checkRedundantDataName = (e, cancelToken) =>
  axiosInstance.get(`api/v1/core-app/currency?name=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });
