import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

//get loan info
export const getLoanInfo = createAsyncThunk("loan-info/getLoanInfo", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getLoanInfo(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Loan Info");
  }
});

export const getAllLoanInfo = createAsyncThunk("loan-info/getAllLoanInfo", async (value, { rejectWithValue }) => {

  const { postsPerPage, loanType, collateralType, inspectionType } = value;
  try {
    const { data } = await API.getAllLoanfo(postsPerPage, loanType, collateralType, inspectionType);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get All Loan Info");
  }
});

export const createLoanInfo = createAsyncThunk("loan-info/createLoanInfo", async (data, { rejectWithValue }) => {
  const { values } = data;
  const { contactPersonName, contactPersonContactNumber, contactPersonEmail, lead, cif, panVatNo } = values;

  try {
    const body = JSON.stringify({
      contactPersonName,
      contactPersonContactNumber,
      contactPersonEmail,
      cif,
      lead,
      panVatNo,
    });
    const { data } = await API.createLoanInfo(body);

    return data;
  } catch (error) {
    return rejectWithValue("Failed to create Loan Info");
  }
});
export const updateLoanInfo = createAsyncThunk("loan-info/updateLoanInfo", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const {
    contactPersonName,
    contactPersonContactNumber,
    contactPersonEmail,
    cif,
    panVatNo,
  } = values;

  try {
    const requestBody = JSON.stringify({
      contactPersonName,
      contactPersonContactNumber,
      contactPersonEmail,
      cif,
      panVatNo,
    });

    const { data: responseData } = await API.updateLoanInfo(id, requestBody);
    return responseData;
  } catch (error) {
    return rejectWithValue("Failed to update Loan Info");
  }
});

// -----------------------------

export const getGuarantor = createAsyncThunk("loan-info/getGuarantor", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getGuarantor(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Guarantor");
  }
});
export const getSpecificLoanInfoGuarantor = createAsyncThunk(
  "loan-info/getSpecificLoanInfoGuarantor",
  async (data, { rejectWithValue }) => {
    //
    const { id, postsPerPage } = data;
    //
    try {
      const { data } = await API.getSpecificLoanInfoGuarantor(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific LoanInfo Guarantor");
    }
  }
);
export const getSpecificGuarantor = createAsyncThunk(
  "leadDetail/getSpecificGuarantor",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificGuarantor(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Guarantor");
    }
  }
);
export const createGuarantor = createAsyncThunk("loan-info/createGuarantor", async (data, { rejectWithValue }) => {
  const {
    fullName,
    nationality,
    citizenshipNo,
    citizenshipIssueDistrict,
    citizenshipIssueDate,
    fatherName,
    motherName,
    grandFatherName,
    grandMotherName,
    spouseName,
    mobileNo,
    totalAsset,
    totalLiability,
    totalWorth,
    loanInfo,
    temporaryAddress,
    permanentAddress,
    sameAsPermanentAddress,
  } = data;

  try {
    const body = JSON.stringify({
      fullName,
      nationality,
      citizenshipNo,
      citizenshipIssueDistrict,
      citizenshipIssueDate,
      fatherName,
      motherName,
      grandFatherName,
      grandMotherName,
      spouseName,
      mobileNo,
      totalAsset,
      totalLiability,
      totalWorth,
      loanInfo,
      temporaryAddress,
      permanentAddress,
      sameAsPermanentAddress,
    });
    const { data } = await API.createGuarantor(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create Guarantor");
  }
});
export const updateGuarantor = createAsyncThunk("loan-info/updateGuarantor", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const {
    fullName,
    nationality,
    citizenshipNo,
    citizenshipIssueDistrict,
    citizenshipIssueDate,
    fatherName,
    motherName,
    grandFatherName,
    grandMotherName,
    spouseName,
    mobileNo,
    totalAsset,
    totalLiability,
    totalWorth,
    loanInfo,
    temporaryAddress,
    permanentAddress,
    sameAsPermanentAddress,
  } = values;
  try {
    const body = JSON.stringify({
      id,
      fullName,
      nationality,
      citizenshipNo,
      citizenshipIssueDistrict,
      citizenshipIssueDate,
      fatherName,
      motherName,
      grandFatherName,
      grandMotherName,
      spouseName,
      mobileNo,
      totalAsset,
      totalLiability,
      totalWorth,
      loanInfo,
      temporaryAddress,
      permanentAddress,
      sameAsPermanentAddress,
    });
    const { data } = await API.updateGuarantor(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update Gurantor");
  }
});

export const getBSIR = createAsyncThunk("loan-info/getBSIR", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getBSIR(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get BSIR");
  }
});

export const getSpecificBSIR = createAsyncThunk("loan-info/getSpecificBSIR", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificBSIR(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get BSIR");
  }
});
export const createBSIR = createAsyncThunk("loan-info/createBSIR", async (data, { rejectWithValue }) => {
  const {
    stockAmt,
    receivableAmt,
    advancedToCustomerAmt,
    advancedFromSupplierAmt,
    creditorsAmt,
    netCurrentAsset,
    reportDateAd,
    file,
    loanInfo,
  } = data;

  try {
    const body = new FormData();
    body.append("stockAmt", stockAmt);
    body.append("receivableAmt", receivableAmt);
    body.append("advancedToCustomerAmt", advancedToCustomerAmt);
    body.append("advancedFromSupplierAmt", advancedFromSupplierAmt);
    body.append("creditorsAmt", creditorsAmt);
    body.append("netCurrentAsset", netCurrentAsset);
    body.append("loanInfo", loanInfo);
    body.append("reportDateAd", reportDateAd);
    if (file) {
      body.append("file", file);
    }
    const { data } = await API.createBSIR(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create BSIR");
  }
});
export const updateBSIR = createAsyncThunk("loan-info/updateBSIR", async (data, { rejectWithValue }) => {
  const { id, values } = data;

  const {
    stockAmt,
    receivableAmt,
    advancedToCustomerAmt,
    advancedFromSupplierAmt,
    creditorsAmt,
    netCurrentAsset,
    reportDateAd,
    file,
    loanInfo,
    detail,
  } = values;

  try {
    const body = new FormData();
    body.append("stockAmt", stockAmt);
    body.append("receivableAmt", receivableAmt);
    body.append("advancedToCustomerAmt", advancedToCustomerAmt);
    body.append("advancedFromSupplierAmt", advancedFromSupplierAmt);
    body.append("creditorsAmt", creditorsAmt);
    body.append("netCurrentAsset", netCurrentAsset);
    body.append("loanInfo", loanInfo);
    body.append("reportDateAd", reportDateAd);
    body.append("detail", detail);
    if (file) {
      body.append("file", file);
    }

    const { data } = await API.updateBSIR(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update BSIR");
  }
});

export const createSisterConcerns = createAsyncThunk(
  "loan-info/createSisterConcerns",
  async (data, { rejectWithValue }) => {
    const { companyName, keyPerson, remarks, loanInfo, businessNature } = data;

    try {
      const body = JSON.stringify({
        companyName,
        remarks,
        keyPerson,
        loanInfo,
        businessNature,
      });
      const { data } = await API.createSisterConcerns(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create sister concern");
    }
  }
);
export const updateSisterConcerns = createAsyncThunk(
  "loan-info/updateSisterConcerns",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { companyName, keyPerson, remarks, loanInfo, businessNature } = values;

    try {
      const body = JSON.stringify({
        companyName,
        remarks,
        keyPerson,
        loanInfo,
        businessNature,
      });
      const { data } = await API.updateSisterConcerns(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update sister concern");
    }
  }
);

export const getSpecificLoanInfoSisterConcerns = createAsyncThunk(
  "loan-info/getSpecificLoanInfoSisterConcerns",
  async (data, { rejectWithValue }) => {
    const { id, postsPerPage } = data;
    try {
      const { data } = await API.getSpecificLoanInfoSisterConcerns(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific LoanInfo Gurantor");
    }
  }
);

export const getSpecificSisterConcerns = createAsyncThunk(
  "leadDetail/getSpecificSisterConcerns",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificSisterConcerns(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Sepcific Sister Concerns");
    }
  }
);

export const createBusinessNature = createAsyncThunk(
  "loan-info/createBusinessNature",
  async (data, { rejectWithValue }) => {
    const { name } = data;
    try {
      const body = JSON.stringify({
        name,
      });
      const { data } = await API.createBusinessNature(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create Business Nature");
    }
  }
);
export const getBusinessNature = createAsyncThunk("leadDetail/getBusinessNature", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getBusinessNature();
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Business Nature");
  }
});

export const createShareholder = createAsyncThunk("loan-info/createShareholder", async (data, { rejectWithValue }) => {
  const { shareHolderName, shareHolderDesignation, shareHoldingAmount, shareHoldingPercentage, loanInfo } = data;

  try {
    const body = JSON.stringify({
      shareHolderName,
      shareHolderDesignation,
      shareHoldingAmount,
      shareHoldingPercentage,
      loanInfo,
    });
    const { data } = await API.createShareholder(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create shareholder");
  }
});

export const updateShareholder = createAsyncThunk("loan-info/updateShareholder", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { shareHolderName, shareHolderDesignation, shareHoldingAmount, shareHoldingPercentage, loanInfo } = values;

  try {
    const body = JSON.stringify({
      shareHolderName,
      shareHolderDesignation,
      shareHoldingAmount,
      shareHoldingPercentage,
      loanInfo,
    });
    const { data } = await API.updateShareholder(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update Shareholder");
  }
});

export const getSpecificLoanInfoShareholder = createAsyncThunk(
  "loan-info/getSpecificLoanInfoShareholder",
  async (data, { rejectWithValue }) => {
    const { id, postsPerPage } = data;
    try {
      const { data } = await API.getSpecificLoanInfoShareholder(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific LoanInfo Gurantor");
    }
  }
);

export const getSpecificShareholder = createAsyncThunk(
  "leadDetail/getSpecificShareholder",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificShareholder(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Sepcific Sister Concerns");
    }
  }
);

export const createBankRelation = createAsyncThunk(
  "loan-info/createBankRelation",
  async (data, { rejectWithValue }) => {
    const { bankName, limitAmt, outstandingValue, bankingRemarks } = data;

    try {
      const body = JSON.stringify({
        bankName,
        limitAmt,
        outstandingValue,
        bankingRemarks,
        facilityType: 1,
      });
      const { data } = await API.createBankRelation(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create bank relation");
    }
  }
);

export const updateBankRelation = createAsyncThunk(
  "loan-info/updateBankRelation",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { bankName, limitAmt, outstandingValue, bankingRemarks, facilityType } = values;

    try {
      const body = JSON.stringify({
        bankName,
        limitAmt,
        outstandingValue,
        bankingRemarks,
        facilityType,
      });
      const { data } = await API.updateBankRelation(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update banking relation");
    }
  }
);

export const getSpecificLoanInfoBankRelation = createAsyncThunk(
  "loan-info/getSpecificLoanInfoBankRelation",
  async (data, { rejectWithValue }) => {
    const { id, postsPerPage } = data;
    try {
      const { data } = await API.getSpecificLoanInfoBankRelation(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific LoanInfo Bank Relation");
    }
  }
);

export const getSpecificBankRelation = createAsyncThunk(
  "leadDetail/getSpecificBankRelation",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificBankRelation(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Sepcific Bank Relation");
    }
  }
);

export const createInspection = createAsyncThunk("loan-info/createInspection", async (data, { rejectWithValue }) => {
  const { collateral, dateOfInspection, natureOfInspection } = data;

  try {
    const body = JSON.stringify({
      collateral,
      dateOfInspection,
      natureOfInspection,
    });
    const { data } = await API.createInspection(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create Inspection");
  }
});

export const updateInspection = createAsyncThunk("loan-info/updateInspection", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { collateral, dateOfInspection, natureOfInspection } = values;

  try {
    const body = JSON.stringify({
      collateral,
      dateOfInspection,
      natureOfInspection,
    });
    const { data } = await API.updateInspection(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update Inspection");
  }
});

export const createFacility = createAsyncThunk("loan-info/createFacility", async (data, { rejectWithValue }) => {
  const { facility, facilityType, purposeOfLimit, requestedLimit, tenureOfLoan, loanInfo } = data;

  try {
    const body = JSON.stringify({
      facility,
      purposeOfLimit,
      requestedLimit,
      tenureOfLoan,
      loanInfo,
      facilityType,
    });
    const { data } = await API.createFacility(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create Facility");
  }
});

export const updateFacility = createAsyncThunk("loan-info/updateFacility", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { facility, purposedLimit, requestedLimit, tenureOfLoan, loanInfo } = values;

  try {
    const body = JSON.stringify({
      facility,
      purposedLimit,
      requestedLimit,
      tenureOfLoan,
      loanInfo,
    });
    const { data } = await API.updateFacility(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update Facility");
  }
});
export const getSpecificFacility = createAsyncThunk(
  "loan-info/getSpecificFacility",
  async (data, { rejectWithValue }) => {
    const { id, postsPerPage } = data;
    try {
      const { data } = await API.getSpecificFacility(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific LoanInfo Facility");
    }
  }
);

// Async Thunk for collateral type

export const getCollateralType = createAsyncThunk("loan-info/getCollateralType", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getCollateralType();
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Collateral Type");
  }
});

export const createCollateralType = createAsyncThunk(
  "loan-info/createCollateralType",
  async (data, { rejectWithValue }) => {
    const { name, createdAtAd, createdBy } = data;
    try {
      const body = JSON.stringify({
        name,
        createdAtAd,
        createdBy,
      });
      const { data } = await API.createCollateralType(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create Collateral Type");
    }
  }
);

export const updateCollateralType = createAsyncThunk(
  "loan-info/updateCollateralType",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name } = values;
    try {
      const body = JSON.stringify({
        name,
      });
      const { data } = await API.updateCollateralType(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update Collateral Type");
    }
  }
);

export const getSpecificCollateralType = createAsyncThunk(
  "loan-info/getSpecificCollateralType",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificCollateralType(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Collateral Type");
    }
  }
);

// Async Thunk for collateral

export const createCollateral = createAsyncThunk("loan-info/createCollateral", async (data, { rejectWithValue }) => {
  const { ownedBy, wardNo, area, plotNo, loanInfo, address, collateralType, district, palika } = data;
  try {
    const body = JSON.stringify({
      ownedBy,
      loanInfo,
      wardNo,
      area,
      plotNo,

      collateralType,
      district,
      palika,
      address,
    });
    const { data } = await API.createCollateral(body);
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data.nonFieldErrors[0]);
  }
});

export const updateCollateral = createAsyncThunk("loan-info/updateCollateral", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { ownedBy, loanInfo, wardNo, address, area, plotNo, createdAtAd } = values;
  try {
    const body = JSON.stringify({
      ownedBy,
      id,
      loanInfo,
      wardNo,
      area,
      plotNo,
      createdAtAd,
      address,
    });
    const { data } = await API.updateCollateral(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to update Collateral");
  }
});
export const getSpecificCollateral = createAsyncThunk(
  "loan-info/getSpecificCollateral",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificCollateral(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Collateral");
    }
  }
);

export const getSpecificLoanInfoCollateral = createAsyncThunk(
  "loan-info/getSpecificLoanInfoCollateral",
  async (data, { rejectWithValue }) => {
    const { id, postsPerPage } = data;
    try {
      const { data } = await API.getSpecificLoanInfoCollateral(id, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Collateral");
    }
  }
);
export const getSpecificCollateralInspection = createAsyncThunk(
  "loan-info/getSpecificCollateralInspection",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificCollateralInspection(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Collateral Inspection");
    }
  }
);
export const getSpecificLeadInspection = createAsyncThunk(
  "loan-info/getSpecificLeadInspection",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificLeadInspection(id);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Lead Inspection");
    }
  }
);
export const getSpecificInspection = createAsyncThunk(
  "loan-info/getSpecificInspection",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificInspection(id);

      return data;
    } catch (error) {
      return rejectWithValue("Failed to get Specific Inspection");
    }
  }
);

export const getAllCollateral = createAsyncThunk(
  "loan-info/getAllCollateral",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllCollateral(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get All Collateral");
    }
  }
);

export const handleSearch = createAsyncThunk("loan-info/handleSearch", async (value, { rejectWithValue }) => {
  const { search, postsPerPage, loanType, status } = value;
  try {
    const { data } = await API.handleSearch(search, postsPerPage, loanType, status);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Leads");
  }
});
