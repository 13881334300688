import { TbMapSearch } from "react-icons/tb";
import { BiCollection } from "react-icons/bi";
import { BsPersonVcard } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";
import { TiLocationArrowOutline } from "react-icons/ti";
import { RiSettings4Line } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";

export const sidebarData = [
  {
    menu: "Leads",
    icon: <TiLocationArrowOutline size={20} />,
    link: "/leads",
    permissions: ["view_loan_lead", "view_loan_lead", "update_loan_lead", "add_loan_lead", "add_loan_lead"],
  },
  {
    menu: "Client Registration",
    icon: <TbReportMoney size={16} />,
    link: "/client-registration",
    permissions: ["view_loan_lead", "view_loan_lead", "update_loan_lead", "add_loan_lead", "add_loan_lead"],
  },
  {
    menu: "Inspection",
    icon: <TbMapSearch size={16} />,
    link: "/inspection",
    permissions: ["view_inspection", "add_inspection", "update_inspection", "delete_inspection", "approve_inspection"],
  },
  {
    menu: "Collection And Recovery",
    icon: <BiCollection size={16} />,
    link: "/collection-and-recovery",
    permissions: ["view_recovery", "add_recovery", "update_recovery", "delete_recovery"],
  },
  {
    menu: "Customer 360",
    icon: <BsPersonVcard size={16} />,
    link: "/customer-details",
    permissions: ["view_report", "add_report", "update_report", "delete_report"],
  },
  {
    menu: "Dashboard",
    icon: <RxDashboard size={16} />,
    link: "/dashboard",
    permissions: [""],
  },
];

export const admin = [
  {
    menu: "Setting",
    icon: <RiSettings4Line size={18} />,
    sub_menu: [
      {
        name: "Department",
        link: "/department",
        permissions: ["add_department", "view_department", "update_department"],
      },
      {
        name: "Hierarchy",
        link: "/hierarchy",
        permissions: ["add_hierarchy", "update_hierarchy", "view_hierarchy"],
      },
      {
        name: "Questions",
        link: "/questions",
        key: "questions",
        permissions: ["view_annex"],
        child_menu: [
          {
            name: "Collateral Questionnaires",
            link: "/collateral-questionnaires",
            permissions: ["view_annex", "add_annex", "update_annex", "delete_annex"],
          },
          {
            name: "Business Questionnaires",
            link: "/business-questionnaires",
            permissions: ["view_annex", "add_annex", "update_annex", "delete_annex"],
          },
        ],
      },
      {
        name: "Core Setup",
        link: "/core-setup",
        key: "coreSetup",
        permissions: ["add_system_setup"],
        child_menu: [
          {
            name: "Organization",
            link: "/organization-setup",
            permissions: ["add_system_setup"],
          },
          {
            name: "Country",
            link: "/country",
            permissions: ["add_country", "view_country", "update_country"],
          },
          {
            name: "Currency",
            link: "/currency",
            permissions: ["update_currency", "add_currency", "view_currency"],
          },
          {
            name: "Fiscal Session AD",
            link: "/fiscalSessionAD",
            permissions: ["add_system_setup"],
          },
          {
            name: "Fiscal Session BS",
            link: "/fiscalSessionBS",
            permissions: ["add_system_setup"],
          },
        ],
      },
      {
        name: "User Setup",
        link: "/user-setup",
        key: "userSetup",
        permissions: [
          "update_user",
          "change_user_password",
          "view_staff",
          "add_user",
          "add_role",
          "view_role",
          "update_role",
        ],
        child_menu: [
          {
            name: "User",
            link: "/user",
            permissions: ["update_user", "change_user_password", "view_staff", "add_user"],
          },
          {
            name: "User Group",
            link: "/user-group",
            permissions: ["add_role", "view_role", "update_role"],
          },
        ],
      },
      {
        name: "Loan Setup",
        link: "/loan-setup",
        key: "loanSetup",

        permissions: [
          "add_share_holder_details",
          "update_share_holder_details",
          "view_share_holder_details",
          "delete_share_holder_details",
          "can_view_collateral",
          "can_update_collateral",
          "can_delete_collateral",
        ],
        child_menu: [
          {
            name: "Facility Type",
            link: "/facility-type",
            permissions: [
              "add_share_holder_details",
              "update_share_holder_details",
              "view_share_holder_details",
              "delete_share_holder_details",
            ],
          },
          {
            name: "Business Nature",
            link: "/business-Nature",
            permissions: [],
          },
          {
            name: "Collateral Type",
            link: "/collateral-type",
            permissions: ["can_view_collateral", "can_update_collateral", "can_delete_collateral"],
          },
        ],
      },
    ],
  },
];
