import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getDepartment,
  getAllDepartment,
  getNext,
  getPrevious,
  getPageDepartment,
  createDepartment,
  updateDepartment,
  handleSearch,
} from "./thunk";

const initialState = {
  departments: [],
  edit: false,
  department: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingDepartment: false,
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    departmentEditSuccess: (state, action) => {
      state.edit = true;
      state.department = state.departments.find(
        (deparment) => deparment.id === action.payload
      );
    },
    clearAllDepartment: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingDepartment = false;
      state.department = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartment.pending, (state) => {
      state.loadingDepartment = true;
    });
    builder.addCase(getDepartment.fulfilled, (state, action) => {
      state.loadingDepartment = false;
      state.edit = false;
      state.departments = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getDepartment.rejected, (state) => {
      state.loadingDepartment = false;
      state.edit = false;
    });
    builder.addCase(createDepartment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createDepartment.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateDepartment.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateDepartment.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllDepartment.pending,
        getPrevious.pending,
        getNext.pending,
        getPageDepartment.pending,
        handleSearch.pending
      ),
      (state) => {
        state.loadingDepartment = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllDepartment.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,
        getPageDepartment.fulfilled,
        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingDepartment = false;
        state.departments = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllDepartment.rejected,
        getPrevious.rejected,
        getNext.rejected,
        getPageDepartment.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingDepartment = false;
      }
    );
  },
});

export const { departmentEditSuccess, clearAllDepartment } =
  departmentSlice.actions;

export default departmentSlice.reducer;
