import axiosInstance from "../../../Utils/axios";

export const getQuestions = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions?offset=0&limit=${postsPerPage}&ordering=-id`
  );

export const getInspections = (postsPerPage, status, loanType,startDate,endDate,collateralType) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/new-inspection?offset=0&limit=${postsPerPage}&status=${
      status !== null ? status?.id : ""
    }&loanType=${loanType !== null ? loanType?.id : ""}&dateOfInspectionStart=${startDate?startDate.toISOString().slice(0, 10):""}&dateOfInspectionEnd=${endDate?endDate.toISOString().slice(0, 10):""}&collateral_Type=${collateralType?collateralType.id:""}`
  );

export const approveInspection = (id) =>
  axiosInstance.patch(`api/v1/loan-inspection-app/inspection-approval/${id}`);

export const getSpecificInspection = (id) =>
  axiosInstance.get(`api/v1/loan-inspection-app/new-inspection/${id}`);

export const getAllQuestions = () =>
  axiosInstance.get(`api/v1/loan-inspection-app/major-enquiry-questions?offset=0&limit=0&ordering=-id`);

export const createQuestion = (body) => {
  return axiosInstance.post(
    `api/v1/loan-inspection-app/major-enquiry-questions`,
    body
  );
};

export const getSpecificQuestion = (id) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions/${id}/summary`
  );

export const updateQuestion = (id, body) =>
  axiosInstance.patch(
    `api/v1/loan-inspection-app/major-enquiry-questions/${id}`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const getQuestionsAnswers = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions-answers?offset=0&limit=${postsPerPage}&ordering=-id`
  );

export const getAllQuestionsAnswers = () =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions-answers?offset=0&limit=0&ordering=-id`
  );

export const createQuestionAnswer = (body) =>
  axiosInstance.post(
    `api/v1/loan-inspection-app/major-enquiry-questions-answers`,
    body
  );

export const getSpecificQuestionAnswer = (id) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions-answers/${id}/summary`
  );

export const updateQuestionAnswer = (id, body) =>
  axiosInstance.patch(
    `api/v1/loan-inspection-app/major-enquiry-questions-answers/${id}`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const getAllCheckListQuestions = (body) => {
  return axiosInstance.get(
    `api/v1/loan-inspection-app/loan-checklist-questions?isActive=true`,
    body
  );
};

export const getAllCheckListQuestionsAnswers = (postsPerPage) => {
  return axiosInstance.get(
    `api/v1/loan-inspection-app/loan-checklist-answers?limit=${0}`
  );
};
export const createCheckList = (body) => {
  return axiosInstance.post(
    `api/v1/loan-inspection-app/loan-checklist-questions`,
    body
  );
};

export const handleSearch = (search, postsPerPage, status, loanType) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/new-inspection?offset=0&limit=${postsPerPage}&search=${search}&status=${
      status !== null ? status?.id : ""
    }&loanType=${loanType !== null ? loanType?.id : ""}`
  );

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);
// checking the redundant

// checking the redundant data
export const checkRedundantData = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/loan-inspection-app/major-enquiry-questions=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
