import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import { createFacility, getFacility, updateFacility } from "../Redux/thunk";
import TextField from "../../../Components/CommonTextField";

const CreateFacilities = ({ masterData, showModal, setShowModal, postsPerPage }) => {
  const dispatch = useDispatch();

  const { facility, edit } = useSelector((state) => state?.facility);
  const initialState = {
    name: edit ? facility?.name : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const createdData = {
      ...values,
    };
    const updatedData = {
      id: facility?.id,
      ...values,
    };
    if (edit) {
      dispatch(updateFacility(updatedData))
        .unwrap()
        .then(() => {
          successFunction("Facility Updated Successfully.");
          dispatch(getFacility(postsPerPage));
          setShowModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    } else {
      dispatch(createFacility(createdData))
        .unwrap()
        .then(() => {
          successFunction("Facility Created Successfully.");
          dispatch(getFacility(postsPerPage));
          setShowModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    }
  };

  return (
    <div>
      <Formik enableReinitialize={true} initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="loan-info  ">
                <div className=" m-0">
                  <TextField
                    type={"text"}
                    name="name"
                    value={formik.values.name}
                    label={"Facility Name"}
                    required
                    formikRequired={formik?.errors?.name && formik?.touched?.name}
                    onChange={(e) => {
                      formik.setFieldValue("name", e.target.value);
                    }}
                  />
                </div>

                <div className="justify-content-end d-flex pt-2">
                  <button type="submit" className="btn create-button" title={"Save"} content={"Save"}>
                    {edit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateFacilities;
