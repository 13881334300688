import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getCurrency,
  getAllCurrency,
  getNext,
  getPrevious,
  getPageCurrency,
  createCurrency,
  updateCurrency,
  handleSearch,
  deletePhoto,
} from "./thunk";

const initialState = {
  currencies: [],
  edit: false,
  currency: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingCurrency: false,
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    currencyEditSuccess: (state, action) => {
      state.edit = true;
      state.currency = state.currencies.find(
        (currency) => currency.id === action.payload
      );
    },
    clearAllCurrency: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingCurrency = false;
      state.currency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrency.pending, (state) => {
      state.loadingCurrency = true;
    });
    builder.addCase(getCurrency.fulfilled, (state, action) => {
      state.loadingCurrency = false;
      state.edit = false;
      state.currencies = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getCurrency.rejected, (state) => {
      state.loadingCurrency = false;
      state.edit = false;
    });
    builder.addCase(createCurrency.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCurrency.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createCurrency.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateCurrency.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateCurrency.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateCurrency.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addMatcher(
      isAnyOf(
        getAllCurrency.pending,
        getPrevious.pending,
        getNext.pending,
        getPageCurrency.pending,
        handleSearch.pending
      ),
      (state) => {
        state.loadingCurrency = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllCurrency.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,
        getPageCurrency.fulfilled,
        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingCurrency = false;
        state.currencies = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllCurrency.rejected,
        getPrevious.rejected,
        getNext.rejected,
        getPageCurrency.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingCurrency = false;
      }
    );
  },
});

export const { currencyEditSuccess, clearAllCurrency } = currencySlice.actions;

export default currencySlice.reducer;
