import axiosInstance from "../../../Utils/axios";

// API for Annexure
export const getAnnex = (id) => axiosInstance.get(`api/v1/survey/annex/${id}/`);
export const getAllAnnex = (annexType) => axiosInstance.get(`api/v1/survey/annex/?annexType=${annexType}`);

export const createAnnex = (body) => axiosInstance.post(`api/v1/survey/annex/`, body);

export const updateAnnex = (id, body) => {
  axiosInstance.patch(`api/v1/survey/annex/${id}/`, body);
};

// API for Question
export const getQuestion = (id) => axiosInstance.get(`api/v1/survey/question/${id}/`);

export const getAllQuestions = (id, questionType, postsPerpage, annexType) =>
  axiosInstance.get(
    `api/v1/survey/question/?annex_Id=${id}&limit=${postsPerpage}&offset=0&answerFormat=${
      questionType ? questionType : ""
    }&annexType=${annexType ? annexType : ""}`
  );

export const createQuestion = (body) => {
  return axiosInstance.post(`api/v1/survey/question/`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateQuestion = (id, body) => axiosInstance.patch(`api/v1/survey/question/${id}/`, body);

// create sub question
export const createSubQuestion = (body) => {
  return axiosInstance.post(`api/v1/survey/sub_question`, body);
};

// API for answer
export const getAnswer = (id) => axiosInstance.get(`api/v1/survey/answer/${id}`);

export const getAllAnswers = (postsPerPage) =>
  axiosInstance.get(`api/v1/survey/answer?offset=0&limit=${postsPerPage}&ordering=-id`);

export const createAnswer = (body) => {
  return axiosInstance.post(`api/v1/survey/answer`, body);
};

export const updateAnswer = (id, body) => axiosInstance.patch(`api/v1/survey/answer/${id}`, body);

// API for question answer
export const getQuestionAnswer = (id) => axiosInstance.get(`api/v1/survey/question_answer/${id}/`);

// export const getAllQuestionAnswers = (postsPerPage) =>
//   axiosInstance.get(`api/v1/survey/question_answer?offset=0&limit=${postsPerPage}&ordering=-id`);

export const createQuestionAnswer = (body) => {
  return axiosInstance.post(`api/v1/survey/question_answer`, body);
};

export const updateQuestionAnswer = (id, body) => axiosInstance.patch(`api/v1/survey/question_answer/${id}`, body);

export const getSubQuestion = (body) =>
  axiosInstance.post(`/api/v1/survey/sub_question/`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export const handleSearch = (id, search, postsPerPage, questionType) =>
  axiosInstance.get(
    `api/v1/survey/question/?annex_Id=${id}&search=${search}&limit=${postsPerPage}&offset=0&answerFormat=${
      questionType ? questionType : ""
    }&ordering=-id`
  );
